import './passwordForgotten.scss';
import { createRef, FormEvent, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Headline from '../headline/Headline';
import { UserApi } from '../../api';
import { showNotification } from '../../notifications';

const PasswordForgotten = () => {
    const recaptchaSiteKey = '6LejofweAAAAACWALWLRRAdDx_aNxD4e5PjELhmP';

    const [formData, setFormData] = useState<{ email: string }>({ email: '' });
    const [requesting, setRequesting] = useState<boolean>(false);
    const recaptchaRef = createRef<ReCAPTCHA>();

    const requestSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current?.getValue();

        if (recaptchaValue) {
            setRequesting(true);

            UserApi.passwordForgotten(formData.email, recaptchaValue)
                .then((response) => {
                    if (response.success) {
                        showNotification('resetPasswordMailSent');
                    } else if (response.code === 'user_not_found') {
                        showNotification('passwordResetWrongMail');
                    } else {
                        showNotification('generalError');
                    }
                    setRequesting(false);
                })
                .catch((error) => {
                    showNotification('generalError');
                    console.error(error);
                    setRequesting(false);
                });
        } else {
            showNotification('doRecaptcha');
        }
    };

    return (
        <div className={'password-forgotten'}>
            <div className={'bg-stwBlue rounded-bl-80 p-10 pt-14 pr-0 relative'} style={{ zIndex: 9999 }}>
                <Headline headline={'Passwort vergessen'} classes={{ headline: 'text-white' }} />
            </div>
            <form className={'space-y-6 p-6 mt-5'} onSubmit={requestSubmitHandler}>
                <div className={'relative z-0 w-full'}>
                    <input
                        id={'reset-link-email'}
                        type={'email'}
                        name={'reset-link-email'}
                        value={formData.email}
                        required
                        placeholder={' '}
                        onChange={(e) => {
                            setFormData((prev) => {
                                return {
                                    ...prev,
                                    email: (e.target as HTMLInputElement).value,
                                };
                            });
                        }}
                        className={
                            'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                        }
                    />
                    <label
                        htmlFor={'reset-link-email'}
                        className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}
                    >
                        E-Mail
                    </label>
                </div>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} className={'flex justify-center'} />
                <div className={'flex justify-center'}>
                    <button
                        type={'submit'}
                        className={
                            'flex justify-center items-center transition-all duration-200 px-6 bg-primary text-white font-semibold text-14 uppercase py-3 w-full rounded-6xl focus:bg-primary-dark hover:bg-primary-dark h-12 disabled:opacity-70 mt-8'
                        }
                        disabled={requesting}
                    >
                        {requesting ? (
                            <svg
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="animate-spin"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                            </svg>
                        ) : (
                            'Link anfordern'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PasswordForgotten;
