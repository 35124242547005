import './headline.scss';
import { ReactNode } from 'react';

const Headline = (props: {
    headline: string;
    subheadline?: string | ReactNode;
    classes?: { headline?: string; subheadline?: string };
}) => {
    return (
        <div className={'headline'}>
            <h1 className={props.classes?.headline} dangerouslySetInnerHTML={{ __html: props.headline }} />
            {props.subheadline && <p className={props.classes?.subheadline}>{props.subheadline}</p>}
        </div>
    );
};

export default Headline;
