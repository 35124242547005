/* eslint-disable react-hooks/exhaustive-deps */
import './digital-tickets.scss';
import Headline from '../headline/Headline';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import Slider from 'react-slick';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';
import { showInstallationNotification, showNotification } from '../../notifications';

const DigitalTickets = () => {
    const stwContext: STWContextValues = useSTWContext();
    const [qrCodeSize, setQrCodeSize] = useState<number>(
        (window.screen.width > 440 ? 440 : window.screen.width) - 136
    );

    const nonNullTickets = stwContext.user?.digitalTickets.filter(ticket => ticket.value != null);

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        autoplay: false,
    };

    useEffect(() => {
        stwContext.loadUserdata(null);
        if (nonNullTickets && nonNullTickets?.length > 0) {
            showNotification("qrCodeBrightness");
        }
    }, []);

    useEffect(() => {
        const recalculateQrSize = () => {
            setQrCodeSize((window.screen.width > 440 ? 440 : window.screen.width) - 136);
        };

        window.addEventListener('resize', recalculateQrSize);
    }, []);

    useEffect(() => {
        showInstallationNotification('pwa-installation-notification-tickets');
    }, []);


    return (
        <div className={'digital-season-ticket'}>
            <div className={'bg-stwGreen-light rounded-bl-80 p-10 pt-14 relative'} style={{ zIndex: 9999 }}>
                <Headline headline={'Tickets & Karten'} classes={{ headline: 'text-stwGreen-dark' }} />
            </div>
            <div className={'px-12 pt-3'}>
                <div className={'flex justify-center'}>
                    <p className={'text-2xl font-bold'}>{stwContext.user?.name}</p>
                </div>
                <div className={'flex justify-around mb-6'}>
                    <div>
                        <p className={'font-semibold'}>
                            {new Date().toLocaleDateString('de-AT', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            })}
                        </p>
                    </div>
                    <div>
                        <p className={'font-semibold'}>
                            {new Date().toLocaleTimeString('de-AT', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </p>
                    </div>
                </div>
                {nonNullTickets && nonNullTickets.length > 0 ? (
                    <Slider {...settings}>
                        {stwContext.user?.digitalTickets.map((ticket, index) => {
                            return (
                                <div className={'flex justify-center relative'} key={index}>
                                    {ticket.type !== null && (
                                        <div className={'flex justify-center mb-4'}>
                                            <p className={'text-2xl'}>{ticket.type}</p>
                                        </div>
                                    )}
                                    <div className={'p-5'}>
                                        <QRCode
                                            value={ticket.value}
                                            className={'w-full h-full'}
                                            size={qrCodeSize}
                                            level={'L'}
                                        />
                                    </div>
                                    {ticket.name !== null && (
                                        <div className={'flex justify-center mt-4'}>
                                            <p className={'text-lg text-center'}>{ticket.name}</p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </Slider>
                ) : (
                    <div className={'flex justify-center -mx-4'}>
                        <p className={'text-base text-justify'}>
                            Hol dir jetzt deine{' '}
                            <Link to={'/products'} className={'text-primary'}>
                                Digitale Saisonkarte
                            </Link>{' '}
                            für unsere Strandbäder und du kannst jederzeit mit deiner Stadtwerke-App durchs Drehkreuz
                            gehen.
                            <br />
                            <br />
                            Du hast noch Fragen zum Produkt? Melde dich unter{' '}
                            <a className={'text-primary'} href={'mailto:superhelden@stw.at'}>
                                superhelden@stw.at
                            </a>
                        </p>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default DigitalTickets;
