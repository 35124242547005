import './news-detail.scss';
import { useEffect, useState } from 'react';
import ImageSlider from '../image-slider/ImageSlider';
import Headline from '../headline/Headline';
import Footer from '../footer/Footer';
import { useParams } from 'react-router-dom';
import { NewsAPI } from '../../api';
import { NewsDTO } from '../../dto';

const NewsDetail = () => {
    const { newsID } = useParams<{ newsID: string }>();
    const [newsData, setNewsData] = useState<NewsDTO>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        NewsAPI.getNews(parseInt(newsID))
            .then((news) => {
                setNewsData(news);
                setLoading(false);
            })
            .catch((e) => console.error(e));
    }, [newsID]);

    return (
        <div className={'product-detail'}>
            {!loading && newsData ? (
                <div className={'container mx-auto'}>
                    {newsData.images && newsData.images.length > 0 && <ImageSlider images={newsData.images} />}
                    <div
                        className={
                            'bg-stwGray-light rounded-tr-80 rounded-bl-80 p-7 z-20 relative ' +
                            (newsData.images.length > 0 && '-mt-40')
                        }
                    >
                        <Headline
                            headline={newsData.title}
                            subheadline={newsData.subtitle}
                            classes={{
                                headline: newsData.images.length > 0 ? '' : 'mt-8',
                            }}
                        />
                        <div className={'bg-white mt-8 mb-8 -mx-3 rounded-5xl p-8'}>
                            <p
                                className={'py-4 text-13 font-medium text-stwGray-semiDark'}
                                dangerouslySetInnerHTML={{
                                    __html: newsData.content,
                                }}
                            />
                            <p className={'text-primary mt-3'}>{newsData.date}</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            ) : (
                <div className={'container mx-auto'}>
                    <div className={'bg-gray-400 h-slider rounded-lg animate-pulse'} />
                    <div className={'bg-stwGray-light rounded-tr-80 rounded-bl-80 -mt-40 p-7 z-20 relative'}>
                        <div className={'bg-gray-400 h-8 rounded-lg animate-pulse mb-2 w-4/5'} />
                        <div className={'bg-gray-400 h-6 rounded-lg animate-pulse w-3/5 mb-4'} />
                        <div className={'bg-white mt-8 mb-8 -mx-3 rounded-5xl p-8'}>
                            <div className={'bg-gray-400 h-32 rounded-lg animate-pulse w-full py-4'} />
                            <div className={'bg-gray-400 h-6 rounded-lg animate-pulse w-2/5 mt-3'} />
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </div>
    );
};

export default NewsDetail;
