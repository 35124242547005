import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <div className={'flex justify-center my-10'}>
                <img src={'/stw_logo_rgb_mobile.svg'} alt={'Logo'} />
            </div>
            <div className={'bg-stwGray-light w-full p-10 text-center'}>
                <ul className={'space-y-3 text-stwGray-dark opacity-40 text-15'}>
                    <li>
                        <Link to={'/content/impressum'}>Impressum</Link>
                    </li>
                    <li>
                        <Link to={'/content/datenschutz'}>Datenschutz</Link>
                    </li>
                    <li>
                        <Link to={'/content/teilnahmebedingungen'}>Teilnahmebedingungen</Link>
                    </li>
                    <li>
                        <Link to={'/content/faq'}>FAQ - Woher kommen die STWs?</Link>
                    </li>
                    <li className={'text-11 pt-3'}>{new Date().getFullYear()} | Stadtwerke Klagenfurt AG</li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
