import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { ProjectDTO } from '../dto';

interface ProjectsState {
    userProjects: {
        loadingData: boolean;
        projects: ProjectDTO[];
        stwAmount: number;
    };
    doneProjects: {
        loadingData: boolean;
        projects: ProjectDTO[];
        stwAmount: number;
    };
    pendingProjects: {
        loadingData: boolean;
        projects: ProjectDTO[];
    };
    projectDetail: {
        loadingData: boolean;
        supportBar: boolean;
        data: ProjectDTO | null;
    };
}

export interface ProjectsValues extends ProjectsState {
    setState: Dispatch<SetStateAction<ProjectsState>>;
}

const ProjectsContext = createContext<ProjectsValues | undefined>(undefined);

export const useProjectsContext = (): ProjectsValues => {
    const context = useContext(ProjectsContext);

    if (context === undefined) {
        throw new Error('ProjectsContext undefined');
    }

    return context;
};

const ProjectsContextProvider = (props: { children: ReactNode[] | ReactNode }) => {
    const [state, setState] = useState<ProjectsState>({
        userProjects: {
            loadingData: true,
            projects: [],
            stwAmount: 0,
        },
        doneProjects: {
            loadingData: true,
            projects: [],
            stwAmount: 0,
        },
        pendingProjects: {
            loadingData: true,
            projects: [],
        },
        projectDetail: {
            loadingData: true,
            supportBar: false,
            data: null,
        },
    });

    const values: ProjectsValues = {
        ...state,
        setState: setState,
    };

    return <ProjectsContext.Provider value={values}>{props.children}</ProjectsContext.Provider>;
};

export default ProjectsContextProvider;
