import './product-detail-overview.scss';
import Stws from '../../stws/Stws';
import { useProductDetailContext } from '../../../contexts/ProductDetailContext';
import BasicNumber from '../../utils/basic-number/BasicNumber';

const ProductDetailOverview = () => {
    const productDetailContext = useProductDetailContext();

    return (
        <div className={'product-detail-overview bg-white mt-7 -m-3 rounded-5xl p-6'}>
            {!productDetailContext.loadingData && productDetailContext.productDetailData ? (
                <div>
                    <div className="flex flex-row justify-between items-center">
                        <div className={'w-full text-center product-detail-stw-bonus-counter'}>
                            <Stws amount={productDetailContext.productDetailData.stwBonus} />
                        </div>

                        {productDetailContext.productDetailData.stwReward >= 0 && (
                            <div className={'w-full text-center product-detail-stw-reward-counter'}>
                                <Stws amount={productDetailContext.productDetailData.stwReward} />
                            </div>
                        )}

                        {productDetailContext.productDetailData.co2 >= 0 && (
                            <div className={'w-full text-center'}>
                                <BasicNumber
                                    amount={productDetailContext.productDetailData.co2}
                                    customClass={'text-stwGray-mid text-20 font-extrabold'}
                                    decimal={true}
                                    suffix={' kg'}
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex flex-row justify-between items-center mt-1">
                        <div className={'w-full text-center'}>
                            <p>Extra Bonus</p>
                        </div>

                        {productDetailContext.productDetailData.stwReward >= 0 && (
                            <div className={'w-full text-center'}>
                                <p>
                                    STW<span className={'lowercase'}>s</span> / Monat
                                </p>
                            </div>
                        )}

                        {productDetailContext.productDetailData.co2 >= 0 && (
                            <div className={'w-full text-center'}>
                                <p>CO2 / Monat</p>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex flex-row justify-between items-center">
                        <div className={'w-full text-center project-detail-stw-counter-invested'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                        </div>

                        <div className={'w-full text-center'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                        </div>

                        <div className={'w-full text-center'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mt-1">
                        <div className={'w-full text-center'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 mx-3'} />
                        </div>

                        <div className={'w-full text-center project-detail-stw-counter-goal'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 mx-3'} />
                        </div>

                        <div className={'w-full text-center'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 mx-3'} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ProductDetailOverview;
