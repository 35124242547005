import './dashboard-product-list.scss';
import Headline from '../../headline/Headline';
import { Link } from 'react-router-dom';
import Stws from '../../stws/Stws';
import { useEffect, useState } from 'react';
import { ProductAPI } from '../../../api';
import { ProductDTO } from '../../../dto';

const DashboardProductList = () => {
    const [allProducts, setAllProducts] = useState<ProductDTO[] | []>([]);

    useEffect(() => {
        let isMounted = true;

        ProductAPI.getAllProducts()
            .then((products) => {
                if (isMounted) {
                    setAllProducts(products);
                }

                return () => {
                    isMounted = false;
                };
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div
            className={'dashboard-product-list bg-primary rounded-bl-9xl p-10 pr-0 relative -mt-32 pt-40'}
            style={{ zIndex: 980 }}
        >
            {(new Date()).valueOf() >= (new Date('2023-06-01')).valueOf() ? (
                <Headline headline={'STWs sammeln'} subheadline={'Mit unseren Produkten sammelst du monatliche STWs'} />
            ) : (
                <Headline headline={'Badehütten Gewinnspiel'} />
            )}

            <div className={'horizontal-slider-container mt-4 flex overflow-x-auto -space-x-7'}>
                {allProducts.map((product) => {
                    return (
                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-primary min-w-52'
                            }
                            key={product.id}
                        >
                            <Link to={'/product/' + product.id} className={'w-full block h-full bg-white'}>
                                <img
                                    alt={product.title}
                                    src={product.images !== [] ? product.images[0] : ''}
                                    className={'h-40 w-full object-cover'}
                                />
                                <div className={'bg-white w-175 p-5'}>
                                    <h2 className={'mb-3'}>{product.title}</h2>
                                    {product.stwReward > 0 && (
                                        <p className={'text-stwGray-dark opacity-50 font-medium text-11'}>
                                            Monatlich: <Stws amount={product.stwReward} />
                                        </p>
                                    )}
                                    {product.stwBonus > 0 && (
                                        <p className={'text-stwGray-dark opacity-50 font-medium text-11'}>
                                            Extra Bonus: <Stws amount={product.stwBonus} />
                                        </p>
                                    )}
                                </div>
                            </Link>
                        </div>
                    );
                })}

                {allProducts.length <= 0 && (
                    <div className={'flex overflow-x-auto -space-x-7'}>
                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-primary min-w-52'
                            }
                        >
                            <div className={'w-full block bg-white h-full'}>
                                <div className={'h-40 w-full object-cover bg-gray-400'} />
                                <div className={'w-full p-5 pr-8'}>
                                    <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                    <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-primary min-w-52'
                            }
                        >
                            <div className={'w-full block bg-white h-full'}>
                                <div className={'h-40 w-full object-cover bg-gray-400'} />
                                <div className={'w-full p-5 pr-8'}>
                                    <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                    <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardProductList;
