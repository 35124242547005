/* eslint-disable react-hooks/exhaustive-deps */
import './dashboard-user-overview.scss';
import { Link } from 'react-router-dom';
import Headline from '../../headline/Headline';
import Stws from '../../stws/Stws';
import BasicNumber from '../../utils/basic-number/BasicNumber';
import { STWContextValues, useSTWContext } from '../../../contexts/STWContext';
import { useEffect, useState } from 'react';
import { BlockchainApi, ProjectAPI } from '../../../api';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';
import { ProjectDTO } from '../../../dto';
import DateFormatter from '../../utils/date-formatter/DateFormatter';
import { GiThreeLeaves } from 'react-icons/gi';

const DashboardUserOverview = () => {
    const showCO2: boolean = false;

    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();
    const [co2, setCO2] = useState<number>(0);
    const [barWidths, setBarWidths] = useState<number[]>([0, 0, 0]);

    useEffect(() => {
        if (showCO2) {
            BlockchainApi.getCO2().then((co2) => {
                setCO2(co2.co2_sum);
            });
        }
    }, []);

    useEffect(() => {
        if (projectsContext.pendingProjects.projects.length > 0) {
            let widths = [];

            for (let i = 0; i < projectsContext.pendingProjects.projects.length; i++) {
                let width = Math.round(
                    (projectsContext.pendingProjects.projects[i].stws.invested /
                        projectsContext.pendingProjects.projects[i].stws.goal) *
                        100
                );

                widths[i] = width > 100 ? 100 : width;
            }
            setBarWidths(widths);
        } else {
            setBarWidths([0, 0, 0]);
        }
    }, [projectsContext.pendingProjects.projects]);

    useEffect(() => {
        ProjectAPI.getOpenProjects().then((projects: ProjectDTO[]) => {
            projectsContext.setState((prev) => {
                return {
                    ...prev,
                    pendingProjects: {
                        projects: projects.sort((a, b) => {
                            return b.stws.invested - a.stws.invested;
                        }),
                        loadingData: false,
                    },
                };
            });
        });
    }, []);

    return (
        <div className={'dashboard-user-overview'} style={{ zIndex: 1000 }}>
            <div className={'stw-user-amount'}>
                <Headline headline={'Hallo ' + stwContext.user?.name} subheadline={'Du hast'} />
                <p>
                    <Stws amount={stwContext.user?.stwAmount} />
                </p>
            </div>
            <div className={'mt-8'}>
                {projectsContext.pendingProjects.projects.length > 0 ? (
                    <div className={'pt-4 pb-6 px-0'}>
                        <h2 className={'text-16 font-bold text-stwGray-dark'}>
                            Aktueller Stand der Mach’s Schöner Projekte
                            {projectsContext.pendingProjects.projects.length > 0 ? (
                                <>
                                    <span> - Enddatum: </span>
                                    <DateFormatter
                                        date={projectsContext.pendingProjects.projects[0].endDate}
                                        config={{
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        }}
                                    />
                                </>
                            ) : (
                                ''
                            )}
                        </h2>
                        {projectsContext.pendingProjects.projects.map((project, index) => {
                            return (
                                <div key={index}>
                                    <div className="w-full h-3 bg-stwGreen-light rounded-full mt-3">
                                        <div
                                            className="h-full bg-primary rounded-full"
                                            style={{
                                                width: barWidths[index] + '%',
                                            }}
                                        />
                                    </div>
                                    <div className={'flex flex-row justify-between mt-2 first-project-counter'}>
                                        <div>
                                            <p className={'text-11 font-semibold normal-case'}>
                                                <Link to={'/project/' + project.id}>{project.title}</Link>
                                            </p>
                                        </div>
                                        <div>
                                            <p className={'text-right w-max text-11 font-medium'}>
                                                <BasicNumber
                                                    prefix={'Platz '}
                                                    amount={index + 1}
                                                    customClass={'text-14 normal-case'}
                                                />
                                                <br />
                                                <Stws amount={project.stws.invested} />
                                                {' investiert'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={'pt-4 pb-6 px-0'}>
                        <div className={'w-2/3 h-5 bg-gray-400 animate-pulse rounded-full'} />
                        <div className={'w-full h-3 bg-gray-400 animate-pulse rounded-full mt-3'} />
                        <div className={'flex flex-row justify-between mt-2'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            <div>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-20 mb-1 ml-16'} />
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            </div>
                        </div>
                        <div className={'w-full h-3 bg-gray-400 animate-pulse rounded-full mt-3'} />
                        <div className={'flex flex-row justify-between mt-2'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            <div>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-20 mb-1 ml-16'} />
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            </div>
                        </div>
                        <div className={'w-full h-3 bg-gray-400 animate-pulse rounded-full mt-3'} />
                        <div className={'flex flex-row justify-between mt-2'}>
                            <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            <div>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-20 mb-1 ml-16'} />
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            </div>
                        </div>
                    </div>
                )}
                {showCO2 && (
                    <div className={'mt-5'}>
                        <div
                            className={
                                'flex flex-row justify-between items-center text-stwGray-dark font-semibold text-11 opacity-50 mt-1'
                            }
                        >
                            <div>
                                <Link to={'/content/faq'} className={'font-medium text-13'}>
                                    Gemeinsame CO2-Einsparung
                                </Link>
                            </div>
                            <div>
                                <p className={'flex items-center justify-center text-stwGreen-dark font-bold'}>
                                    <BasicNumber amount={co2} decimal={true} suffix={' kg'} />
                                    <GiThreeLeaves className={'ml-1 text-13'} />
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardUserOverview;
