import { BsShare } from 'react-icons/bs';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';
import { showNotification } from '../../../notifications';

const ProjectDetailDescription = () => {
    const projectsContext: ProjectsValues = useProjectsContext();

    const handleShareClick = () => {
        if (navigator.share && projectsContext.projectDetail.data) {
            navigator
                .share({
                    title: projectsContext.projectDetail.data.title,
                    text:
                        'Hey, bitte hilf mir dabei Klagenfurt schöner zu machen und werde auch zum Superhelden! Bitte unterstütze: ' +
                        projectsContext.projectDetail.data.title,
                    url: projectsContext.projectDetail.data.id.toString(),
                })
                .then(() => {
                    showNotification('afterSharing');
                })
                .catch((error) => {
                    showNotification('generalError');
                    console.error(error);
                });
        } else {
            showNotification('generalError');
            console.error('navigator.share not defined');
        }
    };

    return (
        <div className={'bg-white mt-8 mb-8 -mx-3 rounded-5xl p-8'}>
            {!projectsContext.projectDetail.loadingData && projectsContext.projectDetail.data ? (
                <div>
                    <h4 className={'font-bold text-stwGray-dark text-16'}>
                        {projectsContext.projectDetail.data.description.title}
                    </h4>
                    <h5 className={'font-medium text-stwGray-dark opacity-50 text-12'}>
                        {projectsContext.projectDetail.data.description.subtitle}
                    </h5>
                    <p
                        className={'py-4 text-13 font-medium text-stwGray-semiDark'}
                        dangerouslySetInnerHTML={{
                            __html: projectsContext.projectDetail.data.description.text,
                        }}
                    />
                    {
                        // @ts-ignore
                        navigator.share && (
                            <span>
                                <BsShare className={'text-stwGray-semiDark mt-3'} onClick={handleShareClick} />
                            </span>
                        )
                    }
                </div>
            ) : (
                <div>
                    <div className={'h-5 bg-gray-400 animate-pulse rounded-lg mb-2 w-4/5'} />
                    <div className={'h-3 bg-gray-400 animate-pulse rounded-lg mb-4 w-3/5'} />
                    <div className={'h-24 bg-gray-400 animate-pulse rounded-lg'} />
                    <span>
                        <BsShare className={'text-stwGray-semiDark mt-3'} />
                    </span>
                </div>
            )}
        </div>
    );
};

export default ProjectDetailDescription;
