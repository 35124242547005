export const SsoHandler = {
    convertToSSOLink: async (link, userID) => {
        let url = new URL(link);

        const ssoTokenResponse = await fetch(
            process.env.REACT_APP_STW_URL + 'api/sso-token.php?user_id=' + (userID ?? ''),
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer 02865e4c8fbe68881eae845e6f7afebe',
                },
                method: 'GET',
            }
        )
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                console.error(error);
                return {};
            });

        if (ssoTokenResponse !== {} && ssoTokenResponse.success) {
            url.searchParams.set('user_id', ssoTokenResponse.data.user_id);
            url.searchParams.set('sso_token', ssoTokenResponse.data.sso_token);
        }

        return url;
    },
};
