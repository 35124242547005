/* eslint-disable react-hooks/exhaustive-deps */
import './projects.scss';
import Headline from '../headline/Headline';
import Stws from '../stws/Stws';
import Footer from '../footer/Footer';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectsValues, useProjectsContext } from '../../contexts/ProjectsContext';
import ProjectsList from './projects-list/ProjectsList';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { useEffect } from 'react';
import { ProjectAPI } from '../../api';
import { ProjectDTO } from '../../dto';

const Projects = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const { url } = useRouteMatch();

    useEffect(() => {
        if (projectsContext.userProjects.projects.length <= 0 && stwContext.user !== null) {
            projectsContext.setState((prev) => {
                return {
                    ...prev,
                    userProjects: {
                        ...prev.userProjects,
                        loadingData: true,
                    },
                };
            });

            ProjectAPI.getUserProjects()
                .then((projects: ProjectDTO[]) => {
                    const stwInvests: number[] = projects.map((project) => {
                        return project.userInvest;
                    });

                    const stwAmount: number =
                        stwInvests.length !== 0 ? stwInvests.reduce((prev, curr) => prev + curr) : 0;

                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            userProjects: {
                                stwAmount: stwAmount,
                                projects: projects,
                                loadingData: false,
                            },
                        };
                    });
                })
                .catch((error) => console.error(error));
        }
    }, [stwContext.user]);

    useEffect(() => {
        if (projectsContext.doneProjects.projects.length <= 0) {
            projectsContext.setState((prev) => {
                return {
                    ...prev,
                    doneProjects: {
                        ...prev.doneProjects,
                        loadingData: true,
                    },
                };
            });

            ProjectAPI.getClosedProjects()
                .then((projects: ProjectDTO[]) => {
                    const stwInvests: number[] = projects.map((project) => {
                        return project.userInvest;
                    });

                    const stwAmount: number =
                        stwInvests.length !== 0 ? stwInvests.reduce((prev, curr) => prev + curr) : 0;

                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            doneProjects: {
                                stwAmount: stwAmount,
                                projects: projects,
                                loadingData: false,
                            },
                        };
                    });
                })
                .catch((error) => console.error(error));
        }

        if (projectsContext.pendingProjects.projects.length <= 0) {
            projectsContext.setState((prev) => {
                return {
                    ...prev,
                    pendingProjects: {
                        ...prev.pendingProjects,
                        loadingData: true,
                    },
                };
            });

            ProjectAPI.getOpenProjects()
                .then((projects: ProjectDTO[]) => {
                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            pendingProjects: {
                                projects: projects.sort((a, b) => {
                                    return b.stws.invested - a.stws.invested;
                                }),
                                loadingData: false,
                            },
                        };
                    });
                })
                .catch((error) => console.error(error));
        }
    }, []);

    return (
        <Switch>
            <Route exact path={url}>
                <div className={'projects'}>
                    <div className={'bg-white rounded-bl-80 p-10 pt-14 z-40 relative'}>
                        <Headline headline={'Projekte'} classes={{ headline: 'text-stwGreen-dark' }} />
                    </div>

                    {stwContext.loggedIn && projectsContext.userProjects.projects.length > 0 && (
                        <Link
                            to={`${url}/supported`.replace('//', '/')}
                            className={
                                'bg-stwGreen-dark overflow-hidden rounded-bl-80 p-10 pt-32 z-30 -mt-20 relative block shadow-lg'
                            }
                        >
                            <Headline headline={'Meine Mach’s Schöner Projekte'} classes={{ headline: 'text-white' }} />
                            <p className={'text-white opacity-75 text-14 font-bold'}>
                                Die Mach’s Schöner Projekte, die du unterstützt an einem Ort.
                            </p>
                        </Link>
                    )}

                    <Link
                        to={`${url}/open`.replace('//', '/')}
                        className={
                            'bg-primary rounded-bl-80 p-10 pt-32 z-20 -mt-20 relative block overflow-hidden shadow-lg'
                        }
                    >
                        <Headline
                            headline={'Derzeitige Mach’s Schöner Projekte'}
                            classes={{ headline: 'text-white' }}
                        />
                        <p className={'text-white opacity-75 text-14 font-bold'}>
                            Hier findest du die aktuellen Mach’s Schöner Projekte.
                        </p>
                    </Link>
                    {projectsContext.doneProjects.projects.length > 0 && (
                        <Link
                            to={`${url}/closed`.replace('//', '/')}
                            className={
                                'bg-stwBlue rounded-bl-80 p-10 pt-32 -mt-20 relative block overflow-hidden z-10 shadow-lg'
                            }
                        >
                            <Headline
                                headline={'Gewinnerprojekte'}
                                classes={{
                                    headline: 'text-white',
                                    subheadline: 'text-stwGray-dark opacity-50 text-14 font-bold',
                                }}
                            />
                            <p className={'text-white opacity-75 text-14 font-bold'}>
                                Hier findest du die Gewinnerprojekte der letzten Runden.
                            </p>
                        </Link>
                    )}
                    <Footer />
                </div>
            </Route>

            <Route exact path={`${url}/supported`}>
                <ProjectsList listType={'supported'} />
                <Footer />
            </Route>
            <Route exact path={`${url}/open`}>
                <ProjectsList listType={'open'} />
                <Footer />
            </Route>
            <Route exact path={`${url}/closed`}>
                <ProjectsList listType={'closed'} />
                <Footer />
            </Route>
        </Switch>
    );
};

export default Projects;
