import './product-detail-stws-support.scss';
import Stws from '../../stws/Stws';
import { STWContextValues, useSTWContext } from '../../../contexts/STWContext';
import BuyButton from '../../buy-button/BuyButton';
import { useProductDetailContext } from '../../../contexts/ProductDetailContext';

const ProductDetailStwsSupport = () => {
    const stwContext: STWContextValues = useSTWContext();
    const productDetailContext = useProductDetailContext();

    const productBought =
        (productDetailContext.productDetailData?.productType === 'waerme' && stwContext.user?.waerme) ||
        (productDetailContext.productDetailData?.productType === 'strom' && stwContext.user?.strom);

    return (
        <>
            <div
                className={[
                    'product-detail-stws-support mt-7 flex',
                    stwContext.loggedIn ? 'justify-between' : 'justify-center',
                ].join(' ')}
            >
                {stwContext.loggedIn && (
                    <div className={'stw-user-amount'}>
                        <p className={'text-primary text-15 font-bold'}>Du hast</p>
                        <p className={'-mt-2'}>
                            <Stws amount={stwContext.user?.stwAmount} />
                        </p>
                    </div>
                )}
                {!productBought && (
                    <div className={'pl-2 flex items-center'}>
                        <BuyButton />
                    </div>
                )}
            </div>
            {productBought && (
                <p className={'mt-3 text-stwGray-dark text-14 font-semibold'}>
                    Als {productDetailContext.productDetailData?.productType === 'waerme' ? 'Fernwärme' : 'Strom'}
                    kunde bekommst du bereits
                    <br />
                    <Stws amount={productDetailContext.productDetailData?.stwReward} /> pro Monat
                </p>
            )}
        </>
    );
};

export default ProductDetailStwsSupport;
