import { ProjectsValues, useProjectsContext } from '../../contexts/ProjectsContext';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { useHistory } from 'react-router-dom';

const SupportAgainButton = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const history = useHistory();

    return (
        <button
            className={'text-primary uppercase bg-white border border-primary rounded-full px-5 py-3 text-11 font-bold'}
            type={'button'}
            onClick={() => {
                if (stwContext.user) {
                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            projectDetail: {
                                ...prev.projectDetail,
                                supportBar: !prev.projectDetail.supportBar,
                            },
                        };
                    });
                } else {
                    history.push('/login');
                }
            }}
        >
            Ich möchte es nochmals unterstützen
        </button>
    );
};

export default SupportAgainButton;
