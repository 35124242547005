import { Settings } from '../dto';

/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path: string) => {
    return `${process.env.REACT_APP_STW_API_URL}settings/${path}`;
};

/**
 * This class provides helper functions to handle api class belonging to products
 */
export class SettingsAPI {
    /**
     * This method gets the setting with the provided nam,e
     * @param key key of the setting
     * @return Promise<any> of selected setting
     * @throws Error if http request is not successful
     */
    static async getSetting(key: Settings): Promise<any> {
        return await fetch(apiUrl(key), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
