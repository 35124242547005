import { ProductDTO } from '../dto';

/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path?: string) => {
    return process.env.REACT_APP_STW_API_URL + 'products/' + (path ?? '');
};

/**
 * This class provides helper functions to handle api class belonging to products
 */
export class ProductAPI {
    /**
     * This method gets all products which are in the wordpress backend
     * @return Promise<ProductDTO[]> array of products
     * @throws Error if http request is not successful
     */
    static async getAllProducts(): Promise<ProductDTO[]> {
        return await fetch(apiUrl(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets a single product by the provided id
     * @param id id of product
     * @return Promise<ProductDTO> of selected product
     * @throws Error if http request is not successful
     */
    static async getProduct(id: number): Promise<ProductDTO> {
        return await fetch(apiUrl(id.toString()), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    static async interestBuy(id: number, sendMails: boolean): Promise<any> {
        return await fetch(apiUrl('interest-buy'), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'POST',
            body: JSON.stringify({
                id: id,
                send_mails: sendMails,
            }),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
