import { NewsDTO } from '../dto';

/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path?: string) => {
    return process.env.REACT_APP_STW_API_URL + 'news/' + (path ?? '');
};

/**
 * This class provides helper functions to handle api class belonging to products
 */
export class NewsAPI {
    /**
     * This method gets all products which are in the wordpress backend
     * @return Promise<ProductDTO[]> array of products
     * @throws Error if http request is not successful
     */
    static async getAllNews(): Promise<NewsDTO[]> {
        return await fetch(apiUrl(), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets a single news by the provided id
     * @param id id of news
     * @return Promise<NewsDTO> of selected news
     * @throws Error if http request is not successful
     */
    static async getNews(id: number): Promise<NewsDTO> {
        return await fetch(apiUrl(id.toString()), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
