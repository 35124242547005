/* eslint-disable react-hooks/exhaustive-deps */
import './project-detail-overview.scss';
import Stws from '../../stws/Stws';
import BasicNumber from '../../utils/basic-number/BasicNumber';
import DateFormatter from '../../utils/date-formatter/DateFormatter';
import SupportAgainButton from '../../support-button/SupportAgainButton';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';
import { useEffect, useState } from 'react';
import { ProjectAPI } from '../../../api';
import { ProjectDTO } from '../../../dto';

const ProjectDetailOverview = () => {
    const projectsContext: ProjectsValues = useProjectsContext();
    const [barWidth, setBarWidth] = useState<number>(0);

    const hideSupportAgainButton: boolean =
        projectsContext.projectDetail.data?.won ||
        projectsContext.projectDetail.data?.userInvest === undefined ||
        projectsContext.projectDetail.data?.userInvest <= 0 ||
        (projectsContext.projectDetail.data?.endDate !== undefined &&
            (new Date(projectsContext.projectDetail.data.endDate)).setHours(0, 0, 0, 0) < (new Date()).setHours(0, 0, 0, 0));

    useEffect(() => {
        if (projectsContext.projectDetail.data) {
            const width = Math.round(
                (projectsContext.projectDetail.data.stws.invested / projectsContext.projectDetail.data.stws.goal) * 100
            );

            setBarWidth(width > 100 ? 100 : width);
        } else {
            setBarWidth(0);
        }
    }, [projectsContext.projectDetail.data]);

    useEffect(() => {
        if (projectsContext.projectDetail.data) {
            ProjectAPI.getOpenProjects().then((projects: ProjectDTO[]) => {
                projectsContext.setState((prev) => {
                    return {
                        ...prev,
                        pendingProjects: {
                            projects: projects.sort((a, b) => {
                                return b.stws.invested - a.stws.invested;
                            }),
                            loadingData: false,
                        },
                    };
                });
            });
        }
    }, []);

    return (
        <div className={'project-detail-overview bg-white mt-7 -m-3 rounded-5xl px-7'}>
            {!projectsContext.projectDetail.loadingData && projectsContext.projectDetail.data ? (
                <div>
                    <div className={'pt-6 pb-4 px-0'}>
                        <div className="flex flex-row justify-between items-center">
                            <div className={'w-full text-center project-detail-stw-counter-invested'}>
                                <Stws amount={projectsContext.projectDetail.data.userInvest} />
                            </div>

                            <div className={'w-full text-center'}>
                                <BasicNumber
                                    amount={projectsContext.projectDetail.data.supporter}
                                    customClass={'text-stwGreen-light2 text-20 font-extrabold'}
                                />
                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mt-1">
                            <div className={'w-full text-center'}>
                                <p>Dein Beitrag</p>
                            </div>

                            <div className={'w-full text-center'}>
                                <p>Unterstützer:innen</p>
                            </div>
                        </div>
                    </div>

                    {!projectsContext.projectDetail.data.won && (
                        <div className={'pt-4 pb-6 px-0'}>
                            <div className="w-full h-3 bg-stwGreen-light rounded-full mt-3">
                                <div
                                    className="h-full bg-primary rounded-full"
                                    style={{
                                        width: barWidth + '%',
                                    }}
                                />
                            </div>
                            <div className={'flex flex-row justify-between mt-2'}>
                                <div>
                                    <p>
                                        Gesammelte STW
                                        <span className={'lowercase'}>s</span>
                                    </p>
                                </div>
                                <div>
                                    <p className={'text-right'}>
                                        <BasicNumber
                                            prefix={'Platz '}
                                            amount={
                                                projectsContext.pendingProjects.projects
                                                    .map((project, key) => {
                                                        return project.id;
                                                    })
                                                    .indexOf(projectsContext.projectDetail.data.id) + 1
                                            }
                                            suffix={` von ${projectsContext.pendingProjects.projects.length}`}
                                            customClass={'text-15 normal-case'}
                                        />
                                        <br />
                                        <Stws amount={projectsContext.projectDetail.data.stws.invested} />
                                        {' gesammelt'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {projectsContext.projectDetail.data.won && (
                        <div className={'pt-4 pb-6 px-0'}>
                            <div className="flex flex-row justify-between items-center">
                                <div className={'w-full text-center'}>
                                    <DateFormatter
                                        date={new Date(projectsContext.projectDetail.data.endDate)}
                                        config={{
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                        }}
                                        className={'text-20 text-stwGray-mid font-extrabold'}
                                    />
                                </div>

                                <div className={'w-full text-center project-detail-stw-counter-invested'}>
                                    <Stws amount={projectsContext.projectDetail.data.stws.invested} />
                                </div>
                            </div>

                            <div className="flex flex-row justify-between items-center mt-1">
                                <div className={'w-full text-center project-detail-stw-counter-goal'}>
                                    <p>Enddatum</p>
                                </div>

                                <div className={'w-full text-center'}>
                                    <p>Endsumme</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={'pt-2 pb-8 px-0 flex justify-center ' + (hideSupportAgainButton && 'hidden')}>
                        <SupportAgainButton />
                    </div>
                </div>
            ) : (
                <div>
                    <div className={'pt-6 pb-4 px-0'}>
                        <div className="flex flex-row justify-between items-center">
                            <div className={'w-full text-center project-detail-stw-counter-invested'}>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                            </div>

                            <div className={'w-full text-center'}>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                            </div>

                            <div className={'w-full text-center'}>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-7 mx-3'} />
                            </div>
                        </div>

                        <div className="flex flex-row justify-between items-center mt-1">
                            <div className={'w-full text-center'}>
                                <p>Dein Beitrag</p>
                            </div>

                            <div className={'w-full text-center project-detail-stw-counter-goal'}>
                                <p>Enddatum</p>
                            </div>

                            <div className={'w-full text-center'}>
                                <p>Unterstützer:innen</p>
                            </div>
                        </div>
                    </div>

                    <div className={'pt-4 pb-6 px-0'}>
                        <div className={'w-full h-3 bg-gray-400 animate-pulse rounded-full mt-3'} />
                        <div className={'flex flex-row justify-between mt-2'}>
                            <div>
                                <p>Gesammelte STWs</p>
                            </div>
                            <div>
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-5 w-20 mb-1 ml-16'} />
                                <div className={'bg-gray-400 rounded-lg animate-pulse h-4 w-36'} />
                            </div>
                        </div>
                    </div>

                    <div className={'pt-2 pb-8 px-0 flex justify-center ' + (hideSupportAgainButton && 'hidden')}>
                        <SupportAgainButton />
                    </div>
                </div>
            )}
        </div>
    );
};
export default ProjectDetailOverview;
