import NumberFormat from 'react-number-format';

const BasicNumber = (props: {
    amount: number | null | undefined;
    customClass?: string;
    decimal?: boolean;
    negative?: boolean;
    prefix?: string;
    suffix?: string;
}) => {
    const amount = props.amount ?? 0;

    return (
        <NumberFormat
            className={props.customClass ?? 'formatted-number'}
            value={amount}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={props.decimal ? 2 : 0}
            allowNegative={props.negative ?? false}
            prefix={props.prefix}
            suffix={props.suffix}
        />
    );
};

export default BasicNumber;
