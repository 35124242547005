import './content.scss';
import { useParams } from 'react-router-dom';
import Headline from '../headline/Headline';
import { useEffect, useState } from 'react';
import { ContentAPI } from '../../api';
import Footer from '../footer/Footer';

const Content = () => {
    const { name } = useParams<{ name: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [content, setContent] = useState<{
        id: number;
        title: string;
        content: string;
        accordion: { text: string; title: string }[] | null;
    } | null>(null);
    const [openAccordion, setOpenAccordion] = useState<EventTarget | null>(null);

    useEffect(() => {
        setLoading(true);
        ContentAPI.getContent(name)
            .then((content) => {
                if (content && (content.code ?? '') !== 'content_not_found') {
                    setContent(content);
                } else {
                    setContent({
                        id: 0,
                        title: 'Nicht verfügbar',
                        content: 'Diese Seite wurde leider nicht gefunden.',
                        accordion: null,
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [name]);

    return (
        <div className={'static-content'}>
            {loading && (
                <>
                    <div className={'bg-primary rounded-bl-80 p-10 pt-14 z-40 relative'}>
                        <div className={'bg-gray-400 h-7 rounded-lg animate-pulse mb-2 w-4/5'} />
                    </div>
                    <div className={'container px-10 mt-10'}>
                        <div className={'bg-gray-400 h-96 rounded-lg animate-pulse mb-2 w-full'} />
                    </div>
                </>
            )}

            {!loading && (
                <>
                    <div className={'bg-primary rounded-bl-80 p-10 pt-14 z-40 relative'}>
                        <Headline headline={content?.title ?? 'Nicht verfügbar'} />
                    </div>
                    <div
                        className={'container px-10 mt-10'}
                        dangerouslySetInnerHTML={{
                            __html: content?.content ?? 'Dieser Inhalt wurde leider nicht gefunden.',
                        }}
                    />
                </>
            )}

            {content?.accordion && (
                <div className={'w-full md:w-3/5 mx-auto p-8'}>
                    <div className={'shadow-md divide-y'}>
                        {content.accordion.map((value, index) => {
                            return (
                                <div className={'tab w-full overflow-hidden relative'} key={index}>
                                    <input
                                        className={'absolute opacity-0'}
                                        id={'accordion-tab-' + index}
                                        type={'radio'}
                                        name={'accordion-tabs'}
                                        onClick={(e) => {
                                            if (e.currentTarget.checked && openAccordion === e.target) {
                                                e.currentTarget.checked = false;
                                                setOpenAccordion(null);
                                            } else {
                                                setOpenAccordion(e.target);
                                            }
                                        }}
                                    />
                                    <label
                                        className="relative block p-5 pr-8 leading-normal cursor-pointer text-stwGray-dark"
                                        htmlFor={'accordion-tab-' + index}
                                    >
                                        {value.title}
                                    </label>
                                    <div
                                        className={
                                            'tab-content border-l-2 bg-stwGray-light border-primary leading-normal overflow-hidden w-full'
                                        }
                                        style={{ marginRight: 0 }}
                                    >
                                        <div
                                            className={'p-5 text-stwGray-dark'}
                                            dangerouslySetInnerHTML={{
                                                __html: value.text.replace('\n', '<br/>').replace('\r\n', '<br/>'),
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default Content;
