import { useProductDetailContext } from '../../../contexts/ProductDetailContext';
import { BsShare } from 'react-icons/bs';
import { showNotification } from '../../../notifications';

const ProductDetailDescription = () => {
    const productDetailContext = useProductDetailContext();

    const handleShareClick = () => {
        if (navigator.share && productDetailContext.productDetailData) {
            navigator
                .share({
                    title: productDetailContext.productDetailData.title,
                    text:
                        'Hey, kennst du schon dieses Stadtwerke Produkt? ' +
                        productDetailContext.productDetailData.title,
                    url: productDetailContext.productDetailData.id.toString(),
                })
                .then(() => {
                    showNotification('afterSharing');
                })
                .catch((error) => {
                    showNotification('generalError');
                    console.error(error);
                });
        } else {
            showNotification('generalError');
            console.error('navigator.share not defined');
        }
    };

    const descriptionBox = (
        <div>
            <h4 className={'font-bold text-stwGray-dark text-16'}>
                {productDetailContext.productDetailData?.description.title}
            </h4>
            <h5 className={'font-medium text-stwGray-dark opacity-50 text-12'}>
                {productDetailContext.productDetailData?.description.subtitle}
            </h5>
            <p
                className={'py-4 text-13 font-medium text-stwGray-semiDark'}
                dangerouslySetInnerHTML={{
                    __html: productDetailContext.productDetailData?.description.text ?? '',
                }}
            />
            {
                // @ts-ignore
                navigator.share && (
                    <span>
                        <BsShare className={'text-stwGray-semiDark mt-3'} onClick={handleShareClick} />
                    </span>
                )
            }
        </div>
    );
    const descriptionNotEmpty =
        productDetailContext.productDetailData?.description.text !== '' &&
        productDetailContext.productDetailData?.description.title !== '' &&
        productDetailContext.productDetailData?.description.subtitle !== '';

    return (
        <div className={'bg-white mt-8 mb-8 -mx-3 rounded-5xl p-8 ' + (!descriptionNotEmpty && 'hidden')}>
            {descriptionNotEmpty && !productDetailContext.loadingData && descriptionBox}
            {productDetailContext.loadingData && (
                <div>
                    <div className={'h-5 bg-gray-400 animate-pulse rounded-lg mb-2 w-4/5'} />
                    <div className={'h-3 bg-gray-400 animate-pulse rounded-lg mb-4 w-3/5'} />
                    <div className={'h-24 bg-gray-400 animate-pulse rounded-lg'} />
                    <span>
                        <BsShare className={'text-stwGray-semiDark mt-3'} />
                    </span>
                </div>
            )}
        </div>
    );
};

export default ProductDetailDescription;
