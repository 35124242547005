import './nav.scss';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { AiOutlineHeart, AiOutlineHome } from 'react-icons/ai';
import { BiExit } from 'react-icons/bi';
import { BsArrowLeft, BsFilter, BsKey } from 'react-icons/bs';
import { IoQrCodeOutline } from 'react-icons/io5';
import { GiThreeLeaves } from 'react-icons/gi';

const Nav = (props: { darkIcons?: boolean }) => {
    const stwContext: STWContextValues = useSTWContext();

    const history = useHistory();
    const path: string = useLocation().pathname;

    history.listen((location, action) => {
        stwContext.setState((prev) => {
            return {
                ...prev,
                nav: false,
            };
        });
    });

    const logoutHandler = () => {
        stwContext.setState((prev) => {
            return {
                ...prev,
                user: null,
            };
        });
        localStorage.removeItem('stw-app-user-token');
        history.push('/');
    };

    return path === '/dashboard' ? (
        <div
            className={'dashboard-nav ' + (stwContext.loggedIn ? 'bg-stwGray-light' : 'bg-primary')}
            style={{ zIndex: 1100 }}
        >
            <div className={'flex justify-between items-start px-8 overflow-hidden relative'} style={{ zIndex: 1100 }}>
                <div className={'flex flex-row justify-center items-center my-7 w-20 px-2'}>
                    <NavLink
                        exact
                        to={'/projects'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <AiOutlineHeart
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            <p className={'text-11 mt-2 font-bold'}>Mach's Schöner</p>
                        </div>
                    </NavLink>
                </div>
                <div className={'flex flex-row justify-center items-center my-7 w-20 px-2'}>
                    <NavLink
                        exact
                        to={'/products'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <GiThreeLeaves
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            {(new Date()).valueOf() >= (new Date('2023-06-01')).valueOf() ? (
                                <p className={'text-11 mt-2 font-bold'}>STWs sammeln</p>
                            ) : (
                                <p className={'text-11 mt-2 font-bold'}>Badehütten Gewinnspiel</p>
                            )}
                        </div>
                    </NavLink>
                </div>

                <div className={'flex flex-row justify-center items-center my-7 w-20 px-2'}>
                    <NavLink
                        exact
                        to={'/digital-tickets'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <IoQrCodeOutline
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            <p className={'text-11 mt-2 font-bold'}>Tickets</p>
                        </div>
                    </NavLink>
                </div>

                {stwContext.loggedIn ? (
                    <div className={'flex flex-row justify-center items-center my-7 w-20 px-2'}>
                        <button
                            className={
                                'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                            }
                            onClick={logoutHandler}
                        >
                            <div className={'text-center'}>
                                <div className={'flex justify-center w-full'}>
                                    <div
                                        className={
                                            'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                        }
                                    >
                                        <BiExit
                                            className={
                                                'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                            }
                                        />
                                    </div>
                                </div>
                                <p className={'text-11 mt-2 font-bold'}>
                                    Abmelden
                                    <br />
                                    &nbsp;
                                </p>
                            </div>
                        </button>
                    </div>
                ) : (
                    <div className={'flex flex-row justify-center items-center my-7 w-20 px-2'}>
                        <NavLink
                            exact
                            to={'/login'}
                            className={
                                'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                            }
                            activeClassName={'nav-active'}
                        >
                            <div className={'text-center'}>
                                <div className={'flex justify-center w-full'}>
                                    <div
                                        className={
                                            'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                        }
                                    >
                                        <BsKey
                                            className={
                                                'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                            }
                                        />
                                    </div>
                                </div>
                                <p className={'text-11 mt-2 font-bold'}>
                                    Anmelden
                                    <br />
                                    &nbsp;
                                </p>
                            </div>
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div className={'absolute top-0 left-0 right-0 w-full'}>
            <div
                className={'flex justify-between fixed w-full top-0 left-0 right-0 mx-auto'}
                style={{ maxWidth: '414px', zIndex: 10001 }}
            >
                <div
                    className={
                        'pt-2 pr-4 z-50 transition duration-200 ' +
                        (props.darkIcons || stwContext.nav ? 'text-stwGray-semiDark' : 'text-white')
                    }
                >
                    <button
                        type={'button'}
                        className={'left-0 pt-1 pl-4 text-xl'}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <BsArrowLeft />
                    </button>
                </div>
                <div
                    className={
                        'pt-2 pr-4 z-50 transition duration-200 ' +
                        (props.darkIcons || stwContext.nav ? 'text-stwGray-semiDark' : 'text-white')
                    }
                >
                    <button
                        type={'button'}
                        className={'p-1 px-2 text-xl'}
                        onClick={() => {
                            stwContext.setState((prev) => {
                                return {
                                    ...prev,
                                    nav: !prev.nav,
                                };
                            });
                        }}
                    >
                        <BsFilter />
                    </button>
                </div>
            </div>
            <div
                className={[
                    'fixed top-0 p-5 z-40 w-full shadow-xl rounded-bl-8xl bg-white transition-all duration-500 ease-in-out mx-auto',
                    stwContext.nav ? 'top-0 right-0 left-0' : '-top-full -right-full left-full',
                ].join(' ')}
                style={{ maxWidth: '414px', zIndex: 10000 }}
            >
                <div className={'flex flex-row justify-center items-center my-5'}>
                    <NavLink
                        exact
                        to={'/dashboard'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <AiOutlineHome
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            <p className={'text-11 mt-2 font-bold'}>Home</p>
                        </div>
                    </NavLink>
                </div>
                <div className={'flex flex-row justify-center items-center my-5'}>
                    <NavLink
                        exact
                        to={'/projects'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <AiOutlineHeart
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            <p className={'text-11 mt-2 font-bold'}>Mach's Schöner</p>
                        </div>
                    </NavLink>
                </div>
                <div className={'flex flex-row justify-center items-center my-5'}>
                    <NavLink
                        exact
                        to={'/products'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <GiThreeLeaves
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>

                            {(new Date()).valueOf() >= (new Date('2023-06-01')).valueOf() ? (
                                <p className={'text-11 mt-2 font-bold'}>STWs sammeln</p>
                            ) : (
                                <p className={'text-11 mt-2 font-bold'}>Badehütten Gewinnspiel</p>
                            )}
                        </div>
                    </NavLink>
                </div>

                <div className={'flex flex-row justify-center items-center my-7'}>
                    <NavLink
                        exact
                        to={'/digital-tickets'}
                        className={
                            'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                        }
                        activeClassName={'nav-active'}
                    >
                        <div className={'text-center'}>
                            <div className={'flex justify-center w-full'}>
                                <div
                                    className={
                                        'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                    }
                                >
                                    <IoQrCodeOutline
                                        className={
                                            'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                        }
                                    />
                                </div>
                            </div>
                            <p className={'text-11 mt-2 font-bold'}>Tickets</p>
                        </div>
                    </NavLink>
                </div>

                {stwContext.loggedIn ? (
                    <div className={'flex flex-row justify-center items-center my-7'}>
                        <button
                            className={
                                'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                            }
                            onClick={logoutHandler}
                        >
                            <div className={'text-center'}>
                                <div className={'flex justify-center w-full'}>
                                    <div
                                        className={
                                            'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                        }
                                    >
                                        <BiExit
                                            className={
                                                'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                            }
                                        />
                                    </div>
                                </div>
                                <p className={'text-11 mt-2 font-bold'}>Abmelden</p>
                            </div>
                        </button>
                    </div>
                ) : (
                    <div className={'flex flex-row justify-center items-center my-7'}>
                        <NavLink
                            exact
                            to={'/login'}
                            className={
                                'transition-all duration-200 ease-in-out nav-link uppercase text-stwGray-semiDark font-bold tracking-wider'
                            }
                            activeClassName={'nav-active'}
                        >
                            <div className={'text-center'}>
                                <div className={'flex justify-center w-full'}>
                                    <div
                                        className={
                                            'nav-icon-container transition-all duration-200 ease-in-out flex justify-center items-center border border-gray-300 rounded-full w-12 h-12'
                                        }
                                    >
                                        <BsKey
                                            className={
                                                'transition-all duration-200 ease-in-out text-stwGray-semiDark text-xl'
                                            }
                                        />
                                    </div>
                                </div>
                                <p className={'text-11 mt-2 font-bold'}>Anmelden</p>
                            </div>
                        </NavLink>
                    </div>
                )}

                <div className={'flex justify-center mt-12 mb-3'}>
                    <img src={'/stw_logo_rgb_mobile.svg'} alt={'Logo'} />
                </div>
            </div>
        </div>
    );
};

export default Nav;
