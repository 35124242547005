import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { UserApi } from '../api';
import { AppUserDTO } from '../dto';
import { showNotification } from '../notifications';

interface STWContextState {
    user: AppUserDTO | null;
    nav: boolean;
}

export interface STWContextValues extends STWContextState {
    setState: Dispatch<SetStateAction<STWContextState>>;
    loggedIn: boolean;
    loadUserdata: (token: string | null, recaptcha?: ReCAPTCHA | null) => void;
}

const STWContext = createContext<STWContextValues | undefined>(undefined);

export const useSTWContext = (): STWContextValues => {
    const context = useContext(STWContext);

    if (context === undefined) {
        throw new Error('STWContext undefined');
    }

    return context;
};

const STWContextProvider = (props: { children: ReactNode[] | ReactNode }) => {
    const [state, setState] = useState<STWContextState>({
        user: null,
        nav: false,
    });

    const loggedIn = () => {
        return state.user !== null;
    };

    const loadUserdata: (token: string | null, recaptcha?: ReCAPTCHA | null) => void = (
        token: string | null,
        recaptcha?: ReCAPTCHA | null,
    ) => {
        if (token === null) {
            token = localStorage.getItem('stw-app-user-token');
        }

        if (token !== null) {
            UserApi.getUser(token)
                .then((response) => {
                    setState((prev) => {
                        return {
                            ...prev,
                            user: {
                                email: response.email,
                                id: response.id,
                                name: response.name,
                                stwAmount: response.stws,
                                waerme: response.waerme,
                                strom: response.strom,
                                digitalTickets: response.digitalTickets
                                    .map((ticket) => {
                                        if ('valid_to' in ticket) {
                                            ticket.valid_to = ticket.valid_to.replace(/\s/, 'T');
                                        }

                                        if ('valid_from' in ticket) {
                                            ticket.valid_from = ticket.valid_from.replace(/\s/, 'T');
                                        }

                                        return ticket;
                                    })
                                    .filter((ticket) => {
                                        return new Date().getTime() <= new Date(ticket.valid_to).getTime();
                                    }),
                            },
                        };
                    });
                })
                .catch((error) => {
                    if (error.message.toLowerCase() === 'unauthorized') {
                        setState((prev) => {
                            return {
                                ...prev,
                                user: null,
                            };
                        });
                        showNotification('generalError');
                        localStorage.removeItem('stw-app-user-token');
                        recaptcha?.reset();
                    }
                });
        }
    };

    useEffect(() => {
        loadUserdata(null);
    }, []);

    const values: STWContextValues = {
        ...state,
        setState: setState,
        loggedIn: loggedIn(),
        loadUserdata: loadUserdata,
    };

    return <STWContext.Provider value={values}>{props.children}</STWContext.Provider>;
};

export default STWContextProvider;
