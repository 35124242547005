/* eslint-disable react-hooks/exhaustive-deps */
import './product-detail.scss';
import { useEffect } from 'react';
import ImageSlider from '../image-slider/ImageSlider';
import Headline from '../headline/Headline';
import ProductDetailStwsSupport from './product-detail-stws-support/ProductDetailStwsSupport';
import ProductDetailOverview from './product-detail-overview/ProductDetailOverview';
import ProductDetailDescription from './product-detail-description/ProductDetailDescription';
import ProductDetailFooterButtons from './product-detail-footer-buttons/ProductDetailFooterButtons';
import Footer from '../footer/Footer';
import { useParams } from 'react-router-dom';
import { useProductDetailContext } from '../../contexts/ProductDetailContext';
import { ProductAPI } from '../../api';

const ProductDetail = () => {
    const productDetailContext = useProductDetailContext();

    const { productID } = useParams<{ productID: string }>();

    useEffect(() => {
        productDetailContext.setState((prev) => {
            return {
                ...prev,
                loadingData: true,
            };
        });

        ProductAPI.getProduct(parseInt(productID))
            .then((product) => {
                productDetailContext.setState((prev) => {
                    return {
                        ...prev,
                        productDetailData: product,
                        loadingData: false,
                    };
                });
            })
            .catch((e) => console.error(e));
    }, [productID]);

    return (
        <div className={'product-detail'}>
            {!productDetailContext.loadingData && productDetailContext.productDetailData ? (
                <div className={'container mx-auto'}>
                    {productDetailContext.productDetailData.images &&
                        productDetailContext.productDetailData.images.length > 0 && (
                            <ImageSlider images={productDetailContext.productDetailData.images} />
                        )}
                    <div
                        className={
                            'bg-stwGray-light rounded-tr-80 rounded-bl-80 p-7 z-20 relative ' +
                            (productDetailContext.productDetailData.images.length > 0 && '-mt-40')
                        }
                    >
                        <Headline
                            headline={productDetailContext.productDetailData.title}
                            subheadline={productDetailContext.productDetailData.subtitle}
                            classes={{
                                headline: productDetailContext.productDetailData.images.length > 0 ? '' : 'mt-8',
                            }}
                        />
                        <ProductDetailStwsSupport />
                        <ProductDetailOverview />
                        <ProductDetailDescription />
                    </div>
                    <ProductDetailFooterButtons />
                    <Footer />
                </div>
            ) : (
                <div className={'container mx-auto'}>
                    <div className={'bg-gray-400 h-slider rounded-lg animate-pulse'} />
                    <div className={'bg-stwGray-light rounded-tr-80 rounded-bl-80 -mt-40 p-7 z-20 relative'}>
                        <div className={'bg-gray-400 h-8 rounded-lg animate-pulse mb-2 w-4/5'} />
                        <div className={'bg-gray-400 h-6 rounded-lg animate-pulse w-3/5'} />
                        <ProductDetailStwsSupport />
                        <ProductDetailOverview />
                        <ProductDetailDescription />
                    </div>
                    <ProductDetailFooterButtons />
                    <Footer />
                </div>
            )}
        </div>
    );
};

export default ProductDetail;
