import './project-detail-stws-support.scss';
import Stws from '../../stws/Stws';
import SupportButton from '../../support-button/SupportButton';
import {STWContextValues, useSTWContext} from '../../../contexts/STWContext';
import {ProjectsValues, useProjectsContext} from '../../../contexts/ProjectsContext';

const ProjectDetailStwsSupport = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const hideSupportButton: boolean =
        projectsContext.projectDetail.data?.won ||
        (projectsContext.projectDetail.data?.userInvest !== undefined &&
            projectsContext.projectDetail.data.userInvest > 0) ||
        (projectsContext.projectDetail.data?.endDate !== undefined &&
            (new Date(projectsContext.projectDetail.data.endDate)).setHours(0, 0, 0, 0) < (new Date()).setHours(0, 0, 0, 0));

    return (
        <>
            <div
                className={[
                    'project-detail-stws-support mt-7 flex',
                    stwContext.loggedIn ? 'justify-between' : 'justify-center',
                ].join(' ')}
            >
                {stwContext.loggedIn && (
                    <div className={'stw-user-amount'}>
                        <p className={'text-white text-15 font-bold'}>Du hast</p>
                        <p className={'-mt-2'}>
                            <Stws amount={stwContext.user?.stwAmount}/>
                        </p>
                    </div>
                )}
                <div className={'flex items-end ' + (hideSupportButton && 'hidden')}>
                    <SupportButton/>
                </div>
            </div>
            {projectsContext.projectDetail.data?.won && (
                <div className={'flex items-center mt-3'}>
                    <p className={'text-stwGreen-dark font-bold text-center'}>Dieses Mach’s Schöner Projekt hat gewonnen 🏆</p>
                </div>
            )}
        </>
    );
};

export default ProjectDetailStwsSupport;
