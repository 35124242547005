/* eslint-disable react-hooks/exhaustive-deps */
import './dashboard-user-project-list.scss';
import Headline from '../../headline/Headline';
import { Link } from 'react-router-dom';
import Stws from '../../stws/Stws';
import { useEffect } from 'react';
import { ProjectAPI } from '../../../api';
import { STWContextValues, useSTWContext } from '../../../contexts/STWContext';
import { ProjectDTO } from '../../../dto';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';

const DashboardUserProjectList = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    useEffect(() => {
        projectsContext.setState((prev) => {
            return {
                ...prev,
                userProjects: {
                    ...prev.userProjects,
                    loadingData: true,
                },
            };
        });

        ProjectAPI.getUserProjects()
            .then((projects: ProjectDTO[]) => {
                const stwInvests: number[] = projects.map((project) => {
                    return project.userInvest;
                });

                const stwAmount: number = stwInvests.length !== 0 ? stwInvests.reduce((prev, curr) => prev + curr) : 0;

                projectsContext.setState((prev) => {
                    return {
                        ...prev,
                        userProjects: {
                            ...prev.userProjects,
                            stwAmount: stwAmount,
                            projects: projects.sort((a, b) => {
                                return b.stws.invested - a.stws.invested;
                            }),
                        },
                    };
                });
            })
            .catch((error) => console.error(error))
            .finally(() => {
                projectsContext.setState((prev) => {
                    return {
                        ...prev,
                        userProjects: {
                            ...prev.userProjects,
                            loadingData: false,
                        },
                    };
                });
            });
    }, [stwContext.user]);

    return (
        <div
            className={'dashboard-user-project-list bg-stwGreen-light rounded-bl-9xl p-10 pr-0 pt-40 -mt-32 relative'}
            style={{ zIndex: 990 }}
        >
            <Headline
                headline={"Meine Mach's Schöner Projekte"}
                subheadline={"Die Mach's Schöner Projekte, die du unterstützt an einem Ort"}
            />

            <div className={'horizontal-slider-container mt-4 flex overflow-x-auto -space-x-7'}>
                {projectsContext.userProjects.projects.map((project) => {
                    return (
                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwGreen-light min-w-52'
                            }
                            key={project.id}
                        >
                            <Link to={'/project/' + project.id} className={'w-full block h-full bg-white'}>
                                <img
                                    alt={project.title}
                                    src={project.images !== [] ? project.images[0] : ''}
                                    className={'h-40 w-full object-cover'}
                                />
                                <div className={'bg-white w-175 p-5'}>
                                    <h2 className={'mb-3'}>{project.title}</h2>
                                    <p className={'text-stwGray-dark opacity-50 font-medium text-11'}>
                                        Dein Beitrag: <Stws amount={project.userInvest} />
                                    </p>
                                    <p className={'text-stwGray-dark opacity-50 font-medium text-11'}>
                                        Platz{' '}
                                        {projectsContext.pendingProjects.projects.findIndex((p) => {
                                            return p.id === project.id;
                                        }) + 1}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    );
                })}

                {projectsContext.userProjects.loadingData && (
                    <div className={'flex overflow-x-auto -space-x-7'}>
                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwGreen-light min-w-52'
                            }
                        >
                            <div className={'w-full block bg-white h-full'}>
                                <div className={'h-40 w-full object-cover bg-gray-400'} />
                                <div className={'w-full p-5 pr-8'}>
                                    <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                    <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwGreen-light min-w-52'
                            }
                        >
                            <div className={'w-full block bg-white h-full'}>
                                <div className={'h-40 w-full object-cover bg-gray-400'} />
                                <div className={'w-full p-5 pr-8'}>
                                    <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                    <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {projectsContext.userProjects.projects.length <= 0 && !projectsContext.userProjects.loadingData && (
                    <p className={'text-stwGray-dark opacity-70 font-medium text-14'}>
                        Du unterstützt zurzeit keine Projekte
                    </p>
                )}
            </div>
        </div>
    );
};

export default DashboardUserProjectList;
