import './products-list.scss';
import Headline from '../../headline/Headline';
import Stws from '../../stws/Stws';
import { useEffect, useState } from 'react';
import { ProductDTO } from '../../../dto';
import { Link } from 'react-router-dom';
import { ProductAPI } from '../../../api';
import Footer from '../../footer/Footer';

const ProductsList = () => {
    const [products, setProducts] = useState<[] | ProductDTO[]>([]);

    useEffect(() => {
        let isMounted = true;

        ProductAPI.getAllProducts()
            .then((products) => {
                if (isMounted) {
                    setProducts(products);
                }

                return () => {
                    isMounted = false;
                };
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div className={'products-list'}>
            <div className={'bg-primary-light rounded-bl-80 p-10 pt-14 relative'} style={{ zIndex: 9999 }}>
                {(new Date()).valueOf() >= (new Date('2023-06-01')).valueOf() ? (
                    <Headline
                        headline={'STWs sammeln'}
                        subheadline={'Mit unseren Produkten sammelst du STWs.'}
                        classes={{ headline: 'text-primary' }}
                    />
                ) : (
                    <Headline
                        headline={'Badehütten Gewinnspiel'}
                        classes={{ headline: 'text-primary' }}
                    />
                )}
            </div>

            {products.map((item, id) => {
                switch (item.categoryPreviewType) {
                    case 1:
                        return (
                            <div
                                className={
                                    'rounded-bl-80 p-10 pb-5 pt-28 -mt-20 relative bg-white border-b border-l border-primary shadow-lg'
                                }
                                style={{ zIndex: 9998 - id }}
                                key={id}
                            >
                                <Link to={`/product/${item.id}`}>
                                    <h2 className={'text-16 font-bold text-stwBlue'}>{item.title}</h2>
                                    <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5'}>
                                        {item.subtitle}
                                    </p>
                                    {item.images.length > 0 && (
                                        <div
                                            className={
                                                'rounded-bl-40 rounded-tr-40 rounded-br-10 rounded-tl-10 overflow-hidden bg-cover bg-center bg-no-repeat w-full h-28'
                                            }
                                            style={{
                                                backgroundImage: `url("${item.images[0]}")`,
                                            }}
                                        />
                                    )}
                                </Link>
                                <div className={'flex flex-row justify-between mt-3 px-4'}>
                                    <div className={'text-primary font-bold text-12'}>
                                        <Stws amount={item.stwBonus} /> Extra Bonus
                                    </div>
                                    {item.stwReward > 0 && (
                                        <div className={'text-stwGray-semiDark font-bold text-12'}>
                                            <p>
                                                <Stws amount={item.stwReward} /> monatlich
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    case 2:
                        return (
                            <div
                                className={
                                    'rounded-bl-80 pt-28 -mt-20 relative bg-cover bg-no-repeat bg-center shadow-lg'
                                }
                                style={{
                                    zIndex: 9998 - id,
                                    backgroundImage: `url("${item.images[0]}")`,
                                }}
                                key={id}
                            >
                                <Link to={`/product/${item.id}`}>
                                    <h2 className={'text-16 font-bold text-stwBlue px-10'}>{item.title}</h2>
                                </Link>
                                <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5 px-10'}>
                                    {item.subtitle}
                                </p>
                                <div
                                    className={
                                        'flex flex-row justify-between px-16 py-8 rounded-bl-80 bg-white bg-opacity-40 backdrop-filter backdrop-blur-sm' +
                                        (item.images.length > 0 ? ' mt-28' : '')
                                    }
                                >
                                    <div className={'text-primary font-bold text-12'}>
                                        <Stws amount={item.stwBonus} /> Extra Bonus
                                    </div>
                                    {item.stwReward > 0 && (
                                        <div className={'text-stwGray-semiDark font-bold text-12'}>
                                            <p>
                                                <Stws amount={item.stwReward} /> monatlich
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    case 3:
                        return (
                            <div
                                className={'rounded-bl-80 p-10 pb-5 pt-28 -mt-20 relative bg-primary-light shadow-lg'}
                                style={{ zIndex: 9998 - id }}
                                key={id}
                            >
                                <div className={'flex flex-row justify-left'}>
                                    {item.images.length > 0 && (
                                        <div className={'mr-5 items-center flex'}>
                                            <img
                                                className={'h-12 w-12 rounded-full'}
                                                src={item.images[0]}
                                                alt={'Preview'}
                                            />
                                        </div>
                                    )}
                                    <div className={'flex items-center'}>
                                        <Link to={`/product/${item.id}`}>
                                            <h2 className={'text-16 font-bold text-stwBlue'}>{item.title}</h2>
                                        </Link>
                                    </div>
                                </div>
                                <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5'}>{item.subtitle}</p>
                                <div
                                    className={
                                        'flex flex-row justify-between px-4' + (item.images.length > 0 ? ' mt-12' : '')
                                    }
                                >
                                    <div className={'text-primary font-bold text-12'}>
                                        <Stws amount={item.stwBonus} /> Extra Bonus
                                    </div>
                                    {item.stwReward > 0 && (
                                        <div className={'text-stwGray-semiDark font-bold text-12'}>
                                            <p>
                                                <Stws amount={item.stwReward} /> monatlich
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    default:
                        return 'no preview type';
                }
            })}
            <Footer />
        </div>
    );
};

export default ProductsList;
