import InvestButton from '../../invest-button/InvestButton';
import { useState } from 'react';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';

const ProjectDetailSupportBar = () => {
    const projectsContext: ProjectsValues = useProjectsContext();
    const [customStwInput, setCustomStwInput] = useState<number | null>(null);

    return (
        <div
            className={
                'transition ease-in-out duration-300 bg-primary fixed shadow-md border-gray-500 px-2 py-5 rounded-t-2xl bottom-0 w-full transform z-50 space-y-4 ' +
                (projectsContext.projectDetail.supportBar ? 'translate-y-0' : 'translate-y-full')
            }
        >
            <div className={'flex'}>
                <div className={'w-full flex px-2'}>
                    <InvestButton amount={50} investID={projectsContext.projectDetail.data?.id} />
                </div>
                <div className={'w-full flex px-2'}>
                    <InvestButton amount={100} investID={projectsContext.projectDetail.data?.id} />
                </div>
                <div className={'w-full flex px-2'}>
                    <InvestButton amount={250} investID={projectsContext.projectDetail.data?.id} />
                </div>
            </div>
            <div className={'flex'}>
                <div className={'flex px-2'}>
                    <input
                        type={'text'}
                        className={
                            'rounded-l-full appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none'
                        }
                        name={'url'}
                        placeholder={'100'}
                        onChange={(event) => {
                            const value = (event.target as HTMLInputElement).value;

                            if (value && !isNaN(parseInt(value))) {
                                setCustomStwInput(parseInt(value));
                            } else {
                                setCustomStwInput(null);
                            }
                        }}
                    />
                    <span className="rounded-r-full inline-flex items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm font-semibold">
                        STWs
                    </span>
                </div>
                <div className={'flex px-2'}>
                    <InvestButton
                        amount={customStwInput ?? 0}
                        investID={projectsContext.projectDetail.data?.id}
                        text={'Investieren'}
                    />
                </div>
            </div>
            <div className={'flex justify-center px-2'}>
                <p className={'text-xs text-center text-white font-semibold'}>
                    Mit dem Unterstützen des Projektes stimmst du den{' '}
                    <a className={'text-green-200'} href={'/content/teilnahmebedingungen'}>
                        Teilnahmebedingungen
                    </a>{' '}
                    zu.
                </p>
            </div>
        </div>
    );
};

export default ProjectDetailSupportBar;
