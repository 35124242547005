/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path: string) => {
    return `${process.env.REACT_APP_STW_API_URL}content/${path}`;
};

/**
 * This class provides helper functions to handle api class belonging to products
 */
export class ContentAPI {
    /**
     * This method gets the content with the provided name
     * @param name name of the content
     * @return Promise<any> of selected content
     * @throws Error if http request is not successful
     */
    static async getContent(name: string): Promise<any> {
        return await fetch(apiUrl(name), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
