import './dashboard-news-list.scss';
import Headline from '../../headline/Headline';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewsAPI } from '../../../api';
import { NewsDTO } from '../../../dto';

const DashboardNewsList = () => {
    const [allNews, setAllNews] = useState<NewsDTO[] | []>([]);

    useEffect(() => {
        NewsAPI.getAllNews()
            .then((news) => {
                setAllNews(news);
            })
            .catch((error) => console.error(error));
    }, []);

    if (allNews.length > 0) {
        return (
            <div
                className={'dashboard-news-list bg-stwBlue rounded-bl-9xl p-10 pr-0 relative -mt-32 pt-40'}
                style={{ zIndex: 970 }}
            >
                <Headline headline={'News'} subheadline={'Hier sind unsere aktuellen News'} />

                <div className={'horizontal-slider-container mt-4 flex overflow-x-auto -space-x-7'}>
                    {allNews.map((news) => {
                        return (
                            <div
                                className={
                                    'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwBlue min-w-52'
                                }
                                key={news.id}
                            >
                                <Link to={'/news/' + news.id} className={'w-full block h-full bg-white'}>
                                    <img
                                        alt={news.title}
                                        src={news.images !== [] ? news.images[0] : ''}
                                        className={'h-40 w-full object-cover'}
                                    />
                                    <div className={'bg-white w-175 p-5'}>
                                        <h2 className={'mb-3'}>{news.title}</h2>
                                        <p className={'text-stwGray-dark opacity-50 font-medium text-11'}>
                                            {news.subtitle}
                                        </p>
                                        <p className={'text-stwGray-semiDark opacity-50 font-medium text-11'}>
                                            {news.date}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}

                    {allNews.length <= 0 && (
                        <div className={'flex overflow-x-auto -space-x-7'}>
                            <div
                                className={
                                    'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwBlue min-w-52'
                                }
                            >
                                <div className={'w-full block bg-white h-full'}>
                                    <div className={'h-40 w-full object-cover bg-gray-400'} />
                                    <div className={'w-full p-5 pr-8'}>
                                        <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                        <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className={
                                    'overflow-hidden rounded-bl-10 rounded-tl-40 md:w-80 cursor-pointer mx-auto my-0 border-2 border-stwBlue min-w-52'
                                }
                            >
                                <div className={'w-full block bg-white h-full'}>
                                    <div className={'h-40 w-full object-cover bg-gray-400'} />
                                    <div className={'w-full p-5 pr-8'}>
                                        <div className={'mb-3 w-full bg-gray-400 h-10 rounded-lg animate-pulse'} />
                                        <div className={'bg-gray-400 h-8 rounded-lg animate-pulse'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default DashboardNewsList;
