import { useState } from 'react';
import { ProductAPI } from '../../api';
import { useProductDetailContext } from '../../contexts/ProductDetailContext';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { showNotification } from '../../notifications';
import { SsoHandler } from '../../sso-handler';

const BuyButton = () => {
    const stwContext: STWContextValues = useSTWContext();
    const productDetailContext = useProductDetailContext();

    const [loading, setLoading] = useState<boolean>(false);

    const redirectButtonRules =
        productDetailContext.productDetailData?.productType === 'redirect' ||
        productDetailContext.productDetailData?.productType === 'redirect_date';

    const clickHandler: () => void = async () => {
        if (!stwContext.user) {
            if (
                productDetailContext.productDetailData?.productType === 'strom' ||
                productDetailContext.productDetailData?.productType === 'waerme'
            ) {
                showNotification('requestOfferStromFernwaermeAnonymous');
            } else {
                showNotification('buyButtonLinkRedirectAnonymous');
            }
        } else {
            if (productDetailContext.productDetailData !== null) {
                if (redirectButtonRules && productDetailContext.productDetailData.buyButtonLink) {
                    SsoHandler.convertToSSOLink(
                        productDetailContext.productDetailData?.buyButtonLink,
                        stwContext.user?.id
                    )
                        .then((url) => {
                            window.location.replace(url.href);
                        })
                        .catch(() => {
                            showNotification('noRedirectLink');
                        });
                }

                setLoading(true);
                ProductAPI.interestBuy(productDetailContext.productDetailData.id, !redirectButtonRules)
                    .then((response) => {
                        if (response.success) {
                            if (
                                productDetailContext.productDetailData?.productType === 'strom' ||
                                productDetailContext.productDetailData?.productType === 'waerme'
                            ) {
                                showNotification('requestOfferStromFernwaerme');
                            } else if(productDetailContext.productDetailData?.productType === 'raffle') {
                                showNotification('raffleJoined');
                            } else {
                                showNotification('requestDefaultOffer');
                            }
                            stwContext.setState((prev) => {
                                if (prev.user !== null) {
                                    return {
                                        ...prev,
                                        user: {
                                            ...prev.user,
                                            stwAmount:
                                                prev.user.stwAmount +
                                                (productDetailContext.productDetailData?.stwBonus ?? 0),
                                        },
                                    };
                                } else {
                                    return prev;
                                }
                            });
                        } else {
                            if (productDetailContext.productDetailData?.productType === 'redirect') {
                                showNotification('stwsAlreadyReceivedToday');
                            } else if(productDetailContext.productDetailData?.productType === 'raffle') {
                                showNotification('raffleAlreadyJoined');
                            } else {
                                showNotification('stwsAlreadyReceived');
                            }
                        }
                    })
                    .catch((error) => {
                        showNotification('generalError');
                        console.error(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    return (
        <button
            type={'button'}
            className={
                'px-8 w-36 bg-primary text-white text-15 font-bold py-3 rounded-tr-5xl rounded-bl-5xl disabled:opacity-70 flex justify-center'
            }
            onClick={clickHandler}
            disabled={loading}
        >
            {loading && (
                <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z" />
                </svg>
            )}
            {!loading && ((redirectButtonRules || productDetailContext.productDetailData?.productType === 'raffle') ? (productDetailContext.productDetailData?.buyButtonText ?? 'Jetzt kaufen') : 'Angebot anfragen')}
        </button>
    );
};

export default BuyButton;
