import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './image-slider.scss';

const ImageSlider = (props: { images: string[] }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <Slider {...settings} className={'w-full h-slider image-slider'}>
            {props.images.map((item, index) => {
                return (
                    <div key={index}>
                        <img src={item} alt={'Picture ' + index} />
                    </div>
                );
            })}
        </Slider>
    );
};

export default ImageSlider;
