import {
    LoginUserdataDTO,
    LoginUserdataResponseDTO,
    RegisterUserdataDTO,
    RegisterUserdataResponseDTO,
    UserdataDTO,
} from '../dto';

/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path?: string) => {
    return process.env.REACT_APP_STW_API_URL + 'users/' + (path ?? '');
};

/**
 * This class provides helper functions to handle api class belonging to the user
 */
export class UserApi {
    /**
     * This method sends the provided login data to the api and returns userdata with the authorization token if successful
     * @param userdata
     * @param recaptcha
     * @return Promise<LoginUserDataDTO> userdata and authorization token
     * @throws Error if http request is not successful
     */
    static async login(userdata: LoginUserdataDTO, recaptcha: string): Promise<LoginUserdataResponseDTO> {
        return await fetch(apiUrl('login'), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                ...userdata,
                recaptcha: recaptcha,
            }),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    static async register(userdata: RegisterUserdataDTO, recaptcha: string): Promise<RegisterUserdataResponseDTO> {
        return await fetch(apiUrl('register'), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                ...userdata,
                recaptcha: recaptcha,
            }),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets the userdata according to the provided token
     * @param token bearer authorization token
     * @return Promise<UserDataDTO> userdata without token
     * @throws Error if http request is not successful
     */
    static async getUser(token: string): Promise<UserdataDTO> {
        return await fetch(apiUrl(), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     *
     * @param email
     * @param recaptcha
     */
    static async passwordForgotten(email: string, recaptcha: string): Promise<any> {
        return await fetch(apiUrl('password-forgotten'), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                email: email,
                recaptcha: recaptcha,
            }),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    static async resetPassword(email: string, password: string, key: string): Promise<any> {
        return await fetch(apiUrl('reset-password'), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                email: email,
                password: password,
                key: key,
            }),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
