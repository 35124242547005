import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { ProductDTO } from '../dto';

interface ProductDetailState {
    productDetailData: ProductDTO | null;
    loadingData: boolean;
}

export interface ProductDetailValues extends ProductDetailState {
    setState: Dispatch<SetStateAction<ProductDetailState>>;
}

const productDetailContext = createContext<ProductDetailValues | undefined>(undefined);

export const useProductDetailContext = (): ProductDetailValues => {
    const context = useContext(productDetailContext);

    if (context === undefined) {
        throw new Error('ProductDetailContext undefined');
    }

    return context;
};

const ProductDetailContextProvider = (props: { children: ReactNode[] | ReactNode }) => {
    const [state, setState] = useState<ProductDetailState>({
        productDetailData: null,
        loadingData: true,
    });

    const values: ProductDetailValues = {
        ...state,
        setState: setState,
    };

    return <productDetailContext.Provider value={values}>{props.children}</productDetailContext.Provider>;
};

export default ProductDetailContextProvider;
