/* eslint-disable react-hooks/exhaustive-deps */
import './projects-list.scss';
import Headline from '../../headline/Headline';
import Stws from '../../stws/Stws';
import { ProjectsValues, useProjectsContext } from '../../../contexts/ProjectsContext';
import { ProjectDTO } from '../../../dto';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsPerson } from 'react-icons/bs';
import BasicNumber from '../../utils/basic-number/BasicNumber';
import DateFormatter from '../../utils/date-formatter/DateFormatter';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { ProjectAPI } from '../../../api';
import * as umgesetztIcon from './umgesetzt.png';
import * as inPlanungIcon from './in_planung.png';

type ListType = 'supported' | 'open' | 'closed';
type TypeData = {
    [key in ListType]: {
        header: string;
        subtitle?: string;
        projects: ProjectDTO[];
    };
};

const ProjectsList = (props: { listType: ListType }) => {
    const projectsContext: ProjectsValues = useProjectsContext();

    const typeData: TypeData = {
        supported: {
            header: 'Meine Mach’s Schöner Projekte',
            subtitle: 'Die Mach’s Schöner Projekte, die du unterstützt an einem Ort.',
            projects: projectsContext.userProjects.projects,
        },
        open: {
            header: 'Derzeitige Mach’s Schöner Projekte',
            subtitle:
                "Ob eines oder alle Projekte: investiere deine STWs und entscheide mit, welches Mach's Schöner Projekt von uns umgesetzt wird.",
            projects: projectsContext.pendingProjects.projects,
        },
        closed: {
            header: 'Gewinnerprojekte',
            subtitle: 'Hier findest du die Gewinnerprojekte der letzten Runden.',
            projects: projectsContext.doneProjects.projects,
        },
    };

    const projects = typeData[props.listType].projects;

    useEffect(() => {
        if (projectsContext.pendingProjects.projects.length <= 0) {
            projectsContext.setState((prev) => {
                return {
                    ...prev,
                    pendingProjects: {
                        ...prev.pendingProjects,
                        loadingData: true,
                    },
                };
            });

            ProjectAPI.getOpenProjects()
                .then((projects: ProjectDTO[]) => {
                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            pendingProjects: {
                                projects: projects.sort((a, b) => {
                                    return b.stws.invested - a.stws.invested;
                                }),
                                loadingData: false,
                            },
                        };
                    });
                })
                .catch((error) => console.error(error));
        }
    }, []);

    return (
        <div className={'projects-list'}>
            <div className={'bg-stwGreen-light rounded-bl-80 p-10 pt-14 relative'} style={{ zIndex: 9999 }}>
                <Headline
                    headline={typeData[props.listType].header}
                    subheadline={typeData[props.listType].subtitle}
                    classes={{ headline: 'text-stwGreen-dark' }}
                />
            </div>
            {/*{props.listType === 'supported' && (*/}
            {/*    <div*/}
            {/*        className={'bg-stwGray-light rounded-bl-80 px-4 pb-6 pt-28 -mt-20 relative block'}*/}
            {/*        style={{ zIndex: 9998 }}*/}
            {/*    >*/}
            {/*        <div className={'grid grid-cols-3 divide-x text-center text-primary'}>*/}
            {/*            <div>*/}
            {/*                <p>*/}
            {/*                    <span className={'font-bold text-20'}>{projects.length}</span>*/}
            {/*                    <br />*/}
            {/*                    <span className={'font-semibold text-11 uppercase opacity-70'}>offen</span>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <p>*/}
            {/*                    <span className={'font-bold text-20'}>*/}
            {/*                        {projectsContext.doneProjects.projects.length}*/}
            {/*                    </span>*/}
            {/*                    <br />*/}
            {/*                    <span className={'font-semibold text-11 uppercase opacity-70'}>abgeschlossen</span>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <p>*/}
            {/*                    <span className={'font-bold text-20'}>*/}
            {/*                        <Stws amount={projectsContext.userProjects.stwAmount} />*/}
            {/*                    </span>*/}
            {/*                    <br />*/}
            {/*                    <span className={'font-semibold text-11 uppercase opacity-70'}>investiert</span>*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {projects.map((item, id) => {
                switch (item.categoryPreviewType) {
                    case 1:
                        return (
                            <div
                                className={'shadow-lg rounded-bl-80 p-10 pb-5 pt-28 -mt-20 relative bg-white'}
                                style={{ zIndex: 9997 - id }}
                                key={id}
                            >
                                <Link to={`/project/${item.id}`}>
                                    <h2 className={'text-16 font-bold text-stwGreen-dark'}>{item.title}</h2>
                                    {props.listType !== 'closed' && (
                                        <h3 className={'text-12 font-normal text-stwGray-dark opacity-50 -mt-1.5'}>
                                            Die Abstimmung endet am{' '}
                                            <DateFormatter
                                                date={item.endDate}
                                                config={{
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                }}
                                            />
                                        </h3>
                                    )}
                                    <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5'}>
                                        {item.subtitle}
                                    </p>
                                    <div
                                        className={
                                            'relative overflow-visible rounded-bl-40 rounded-tr-40 rounded-br-10 rounded-tl-10 overflow-hidden bg-cover bg-center bg-no-repeat w-full h-28'
                                        }
                                        style={{
                                            backgroundImage: `url("${item.images[0]}")`,
                                        }}
                                    >
                                        {item.wonStatus && (
                                            <img className={'absolute -right-6 -top-6 w-20'} src={item.wonStatus === 'planung' ? inPlanungIcon.default : umgesetztIcon.default} />
                                        )}
                                    </div>
                                </Link>
                                <div className={'flex flex-row justify-between mt-3 px-4'}>
                                    {'userInvest' in item && (
                                        <div
                                            className={
                                                'text-primary font-bold text-12 flex items-center justify-center'
                                            }
                                        >
                                            <AiOutlineHeart className={'mr-1'} /> <Stws amount={item.userInvest} />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'text-stwGray-semiDark font-bold text-12 flex items-center justify-center'
                                        }
                                    >
                                        <BsPerson className={'mr-1'} /> <BasicNumber amount={item.supporter} />
                                    </div>
                                    <div className={'text-stwGray-semiDark font-bold text-12'}>
                                        <Stws amount={item.stws.invested} />
                                    </div>
                                </div>
                            </div>
                        );
                    case 2:
                        return (
                            <div
                                className={
                                    'shadow-lg rounded-bl-80 pt-28 -mt-20 relative bg-cover bg-no-repeat bg-center'
                                }
                                style={{
                                    zIndex: 9997 - id,
                                    backgroundImage: `url("${item.images[0]}")`,
                                }}
                                key={id}
                            >
                                <Link to={`/project/${item.id}`}>
                                    <h2 className={'text-16 font-bold text-stwGreen-dark px-10'}>{item.title}</h2>
                                </Link>
                                {props.listType !== 'closed' && (
                                    <h3 className={'text-12 font-normal text-stwGray-dark opacity-50 -mt-1.5 px-10'}>
                                        Die Abstimmung endet am{' '}
                                        <DateFormatter
                                            date={item.endDate}
                                            config={{
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }}
                                        />
                                    </h3>
                                )}
                                <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5 px-10'}>
                                    {item.subtitle}
                                </p>
                                <div
                                    className={
                                        'flex flex-row justify-between mt-28 px-16 py-8 rounded-bl-80 bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm'
                                    }
                                >
                                    {'userInvest' in item && (
                                        <div
                                            className={
                                                'text-primary font-bold text-12 flex items-center justify-center'
                                            }
                                        >
                                            <AiOutlineHeart className={'mr-1'} /> <Stws amount={item.userInvest} />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'text-stwGray-semiDark font-bold text-12 flex items-center justify-center'
                                        }
                                    >
                                        <BsPerson className={'mr-1'} /> <BasicNumber amount={item.supporter} />
                                    </div>
                                    <div className={'text-stwGray-semiDark font-bold text-12'}>
                                        <Stws amount={item.stws.invested} />
                                    </div>
                                </div>
                            </div>
                        );
                    case 3:
                        return (
                            <div
                                className={'shadow-lg rounded-bl-80 p-10 pb-5 pt-28 -mt-20 relative bg-stwGreen-light'}
                                style={{ zIndex: 9997 - id }}
                                key={id}
                            >
                                <div className={'flex flex-row justify-left'}>
                                    <div className={'mr-5 items-center flex'}>
                                        <img
                                            className={'h-12 w-12 rounded-full'}
                                            src={item.images[0]}
                                            alt={'Preview'}
                                        />
                                    </div>
                                    <div className={'flex items-center'}>
                                        <div>
                                            <Link to={`/project/${item.id}`}>
                                                <h2 className={'text-16 font-bold text-stwGreen-dark'}>{item.title}</h2>
                                            </Link>
                                            {props.listType !== 'closed' && (
                                                <p className={'text-12 font-normal text-stwGray-dark opacity-50'}>
                                                    Die Abstimmung endet am{' '}
                                                    <DateFormatter
                                                        date={item.endDate}
                                                        config={{
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                        }}
                                                    />
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <p className={'text-13 font-normal text-stwGray-semiDark mt-2 mb-5'}>{item.subtitle}</p>
                                <div className={'flex flex-row justify-between mt-12 px-4'}>
                                    {'userInvest' in item && (
                                        <div
                                            className={
                                                'text-primary font-bold text-12 flex items-center justify-center'
                                            }
                                        >
                                            <AiOutlineHeart className={'mr-1'} /> <Stws amount={item.userInvest} />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'text-stwGray-semiDark font-bold text-12 flex items-center justify-center'
                                        }
                                    >
                                        <BsPerson className={'mr-1'} /> <BasicNumber amount={item.supporter} />
                                    </div>
                                    <div className={'text-stwGray-semiDark font-bold text-12'}>
                                        <Stws amount={item.stws.invested} />
                                    </div>
                                </div>
                            </div>
                        );
                    default:
                        return 'no category type';
                }
            })}
        </div>
    );
};

export default ProjectsList;
