import { BlockchainData, TransactionDataError } from '../dto';

const apiUrl = (path?: string) => {
    return process.env.REACT_APP_STW_API_URL + 'blockchain/' + (path ?? '');
};

export class BlockchainApi {
    static async add(
        data: BlockchainData
    ): Promise<{ success: false; messages: TransactionDataError[] } | { success: true; data: BlockchainData }> {
        return await fetch(apiUrl('add'), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'POST',
            body: JSON.stringify(data),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    static async getCO2(): Promise<{ co2_sum: number }> {
        return await fetch(apiUrl('co2'), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
