const DateFormatter = (props: { date: Date | string; config?: Intl.DateTimeFormatOptions; className?: string }) => {
    const date =
        props.date instanceof Date
            ? props.date.toLocaleDateString('de-AT', props.config)
            : new Date(props.date).toLocaleDateString('de-AT', props.config);

    return <span className={['formatted-date', props.className ?? ''].join(' ')}>{date}</span>;
};

export default DateFormatter;
