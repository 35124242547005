import { ProjectDTO } from '../dto';

/**
 * This method provides the correct stw app api url with the given path
 * @param path endpoint
 * @return string correct api url
 */
const apiUrl = (path?: string) => {
    return process.env.REACT_APP_STW_API_URL + 'projects/' + (path ?? '');
};

/**
 * This class provides helper functions to handle api class belonging to projects
 */
export class ProjectAPI {
    /**
     * This method gets a single project by the provided id
     * @param id id of project
     * @return Promise<ProjectDTO> of selected project
     * @throws Error if http request is not successful
     */
    static async getProject(id: number): Promise<ProjectDTO> {
        return await fetch(apiUrl(id.toString()), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets all projects which are in the wordpress backend
     * @return Promise<ProjectDTO[]> array of projects
     * @throws Error if http request is not successful
     */
    static async getAllProjects(): Promise<ProjectDTO[]> {
        return await fetch(apiUrl(), {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets all open projects in which the user has not invested
     * @return Promise<ProjectDTO[]> array of open projects
     * @throws Error if http request is not successful
     */
    static async getOpenProjects(): Promise<ProjectDTO[]> {
        return await fetch(apiUrl('open'), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets all closed projects which are in the wordpress backend
     * @return Promise<ProjectDTO[]> array of closed projects
     * @throws Error if http request is not successful
     */
    static async getClosedProjects(): Promise<ProjectDTO[]> {
        return await fetch(apiUrl('closed'), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }

    /**
     * This method gets all projects in which the user has invested
     * @return Promise<ProjectDTO[]> array of user projects
     * @throws Error if http request is not successful
     */
    static async getUserProjects(): Promise<ProjectDTO[]> {
        return await fetch(apiUrl('supported'), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
            },
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
    }
}
