import { Link } from 'react-router-dom';

const ProductDetailFooterButtons = () => {
    return (
        <div className={'m-7 mt-10 space-y-4'}>
            <div className={'flex justify-center'}>
                <Link
                    to={'/products'}
                    className={
                        'py-3 px-4 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-primary text-white uppercase w-3/5 transition ease-in duration-200 text-center text-11 font-bold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full'
                    }
                >
                    Alle Angebote
                </Link>
            </div>
        </div>
    );
};

export default ProductDetailFooterButtons;
