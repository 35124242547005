import { ProjectsValues, useProjectsContext } from '../../contexts/ProjectsContext';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { useHistory } from 'react-router-dom';

const SupportButton = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const history = useHistory();

    return (
        <button
            type={'button'}
            className={'px-6 bg-primary text-white text-15 font-bold py-3 rounded-tr-5xl rounded-bl-5xl'}
            onClick={() => {
                if (stwContext.user) {
                    projectsContext.setState((prev) => {
                        return {
                            ...prev,
                            projectDetail: {
                                ...prev.projectDetail,
                                supportBar: !prev.projectDetail.supportBar,
                            },
                        };
                    });
                } else {
                    history.push('/login');
                }
            }}
        >
            Mach's Schöner
        </button>
    );
};

export default SupportButton;
