import './login.scss';
import { createRef, FormEvent, useState } from 'react';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { Link, useHistory } from 'react-router-dom';
import { UserApi } from '../../api';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoginUserdataDTO, RegisterUserdataDTO } from '../../dto';
import { Notifications, showNotification } from '../../notifications';

const Login = () => {
    const recaptchaSiteKey = '6LejofweAAAAACWALWLRRAdDx_aNxD4e5PjELhmP';

    const stwContext: STWContextValues = useSTWContext();
    const history = useHistory();

    const [tabValue, setTabValue] = useState<number>(0);
    const [loginFormData, setLoginFormData] = useState<LoginUserdataDTO>({
        username: '',
        password: '',
    });
    const [registerFormData, setRegisterFormData] = useState<RegisterUserdataDTO>({
        first_name: '',
        last_name: '',
        email: '',
        salutation: 'herr',
        checkboxes: {
            privacyPolicy: false,
            dataProcessing: false,
            newsletter: false,
        },
    });
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const [registering, setRegistering] = useState<boolean>(false);
    const recaptchaRef = createRef<ReCAPTCHA>();

    const loginSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentCaptcha = recaptchaRef.current;
        const recaptchaValue = currentCaptcha?.getValue();

        if (recaptchaValue) {
            setLoggingIn(true);
            UserApi.login(loginFormData, recaptchaValue)
                .then((response) => {
                    if (response.success) {
                        localStorage.setItem('stw-app-user-token', response.data.token);

                        stwContext.loadUserdata(response.data.token, currentCaptcha);

                        setLoggingIn(false);
                        history.push('/dashboard');
                    } else {
                        toast.error(response.message ?? Notifications['generalError'].message);
                        currentCaptcha?.reset();
                        console.error(response.code);
                        setLoggingIn(false);
                    }

                    if (response.firstLoginBonus) {
                        showNotification('welcomeStws');
                        showNotification('welcomeMessage', {
                            autoClose: false,
                        });
                        setTimeout(() => {
                            showNotification('howToInstallWelcomeMessage', {
                                autoClose: false,
                            });
                        }, 60000);
                    } else if (response.firstLoginBonus === false) {
                        showNotification('bonusStwError');
                    }
                })
                .catch((error) => {
                    showNotification('generalError');
                    console.error(error);
                    setLoggingIn(false);
                });
        } else {
            showNotification('doRecaptcha');
        }
    };

    const registerSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const currentCaptcha = recaptchaRef.current;
        const recaptchaValue = currentCaptcha?.getValue();

        if (recaptchaValue) {
            setRegistering(true);

            UserApi.register(registerFormData, recaptchaValue)
                .then((response) => {
                    if (response.code === 'successfully_registered_app_user') {
                        showNotification('registerMail');
                        setTabValue(0);
                    } else {
                        toast.error(response.message ?? Notifications['registerError'].message);
                        currentCaptcha?.reset();
                        console.error(response.code);
                    }
                    setRegistering(false);
                })
                .catch((error) => {
                    toast.error(error ?? Notifications['registerError'].message);
                    currentCaptcha?.reset();
                    console.error(error);
                    setRegistering(false);
                });
        } else {
            showNotification('doRecaptcha');
        }
    };

    return (
        <div className={'login mt-10 w-full p-6'}>
            <div className={'flex flex-row justify-center space-x-2 tabs'}>
                <div className={'tab'}>
                    <button
                        className={
                            'text-12 text-stwGray-mid text-center uppercase font-semibold px-5 py-2 rounded-6xl transition duration-500' +
                            (tabValue === 0 ? ' active' : '')
                        }
                        onClick={() => {
                            setTabValue(0);
                        }}
                    >
                        Anmelden
                    </button>
                </div>
                <div className={'tab'}>
                    <button
                        className={
                            'text-12 text-stwGray-mid text-center uppercase font-semibold px-5 py-2 rounded-6xl transition duration-500' +
                            (tabValue === 1 ? ' active' : '')
                        }
                        onClick={() => {
                            setTabValue(1);
                        }}
                    >
                        Registrieren
                    </button>
                </div>
            </div>
            <div role={'tabpanel'} hidden={tabValue !== 0} id={'login-panel'} className={'mt-4'}>
                {tabValue === 0 && (
                    <div>
                        <form className={'space-y-6'} onSubmit={loginSubmitHandler}>
                            <div className={'flex justify-center'}>
                                <p className={'text-stwGray-mid text-xs font-semibold text-center'}>
                                    Hier kannst du dich auch mit deinen Zugangsdaten von stw.at anmelden
                                </p>
                            </div>
                            <div className={'relative z-0 w-full'}>
                                <input
                                    id={'login-username'}
                                    type={'text'}
                                    name={'login-username'}
                                    value={loginFormData.username}
                                    required
                                    placeholder={' '}
                                    onChange={(e) => {
                                        setLoginFormData((prev) => {
                                            return {
                                                ...prev,
                                                username: (e.target as HTMLInputElement).value,
                                            };
                                        });
                                    }}
                                    className={
                                        'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                    }
                                />
                                <label
                                    htmlFor={'login-username'}
                                    className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}
                                >
                                    E-Mail
                                </label>
                            </div>
                            <div className={'relative z-0 w-full'}>
                                <input
                                    id={'login-password'}
                                    type={'password'}
                                    name={'login-password'}
                                    value={loginFormData.password}
                                    required
                                    placeholder={' '}
                                    onChange={(e) => {
                                        setLoginFormData((prev) => {
                                            return {
                                                ...prev,
                                                password: (e.target as HTMLInputElement).value,
                                            };
                                        });
                                    }}
                                    className={
                                        'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                    }
                                />
                                <label
                                    htmlFor={'login-password'}
                                    className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}
                                >
                                    Passwort
                                </label>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={recaptchaSiteKey}
                                className={'flex justify-center'}
                            />
                            <div className={'flex justify-center'}>
                                <button
                                    type={'submit'}
                                    className={
                                        'flex justify-center items-center transition-all duration-200 px-6 bg-primary text-white font-semibold text-14 uppercase py-3 w-full rounded-6xl focus:bg-primary-dark hover:bg-primary-dark h-12 disabled:opacity-70 mt-8'
                                    }
                                    disabled={loggingIn}
                                >
                                    {loggingIn ? (
                                        <svg
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='animate-spin'
                                            viewBox='0 0 1792 1792'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z' />
                                        </svg>
                                    ) : (
                                        'Anmelden'
                                    )}
                                </button>
                            </div>
                        </form>
                        <div className={'flex justify-center items-center mt-8'}>
                            <Link to={'/password-forgotten'} className={'text-primary text-sm uppercase'}>
                                Passwort vergessen
                            </Link>
                        </div>
                    </div>
                )}
            </div>
            <div role={'tabpanel'} hidden={tabValue !== 1} id={'register-panel'} className={'mt-4'}>
                {tabValue === 1 && (
                    <div>
                        <form className={'space-y-6'} onSubmit={registerSubmitHandler}>
                            <div className={'relative z-0 w-full'}>
                                <select
                                    id={'register-salutation'}
                                    name={'register-salutation'}
                                    value={registerFormData.salutation}
                                    required
                                    onChange={(e) => {
                                        setRegisterFormData((prev) => {
                                            return {
                                                ...prev,
                                                salutation: (e.target as HTMLSelectElement).value,
                                            };
                                        });
                                    }}
                                    className={
                                        'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                    }
                                >
                                    <option value={'herr'}>Herr</option>
                                    <option value={'frau'}>Frau</option>
                                </select>
                                <label
                                    htmlFor={'register-salutation'}
                                    className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}
                                >
                                    Anrede
                                </label>
                            </div>
                            <div className={'flex'}>
                                <div className={'relative z-0 w-1/2'}>
                                    <input
                                        id={'register-firstname'}
                                        type={'text'}
                                        name={'register-firstname'}
                                        value={registerFormData.first_name}
                                        required
                                        placeholder={' '}
                                        onChange={(e) => {
                                            setRegisterFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    first_name: (e.target as HTMLInputElement).value,
                                                };
                                            });
                                        }}
                                        className={
                                            'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                        }
                                    />
                                    <label
                                        htmlFor={'register-firstname'}
                                        className={
                                            'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'
                                        }
                                    >
                                        Vorname
                                    </label>
                                </div>
                                <div className={'relative z-0 w-1/2'}>
                                    <input
                                        id={'register-lastname'}
                                        type={'text'}
                                        name={'register-lastname'}
                                        value={registerFormData.last_name}
                                        required
                                        placeholder={' '}
                                        onChange={(e) => {
                                            setRegisterFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    last_name: (e.target as HTMLInputElement).value,
                                                };
                                            });
                                        }}
                                        className={
                                            'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                        }
                                    />
                                    <label
                                        htmlFor={'register-lastname'}
                                        className={
                                            'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'
                                        }
                                    >
                                        Nachname
                                    </label>
                                </div>
                            </div>
                            <div className={'relative z-0 w-full'}>
                                <input
                                    id={'register-email'}
                                    type={'email'}
                                    name={'register-email'}
                                    value={registerFormData.email}
                                    required
                                    placeholder={' '}
                                    onChange={(e) => {
                                        setRegisterFormData((prev) => {
                                            return {
                                                ...prev,
                                                email: (e.target as HTMLInputElement).value,
                                            };
                                        });
                                    }}
                                    className={
                                        'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'
                                    }
                                />
                                <label
                                    htmlFor={'register-email'}
                                    className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}
                                >
                                    E-Mail
                                </label>
                            </div>
                            {/*<div className={'relative z-0 w-full'}>*/}
                            {/*  <input*/}
                            {/*    id={'register-customer-number'}*/}
                            {/*    type={'number'}*/}
                            {/*    name={'register-customer-number'}*/}
                            {/*    value={registerFormData.customer_number}*/}
                            {/*    placeholder={' '}*/}
                            {/*    onChange={(e) => {*/}
                            {/*      setRegisterFormData((prev) => {*/}
                            {/*        return {*/}
                            {/*          ...prev,*/}
                            {/*          customer_number: (e.target as HTMLInputElement).value*/}
                            {/*        };*/}
                            {/*      });*/}
                            {/*    }}*/}
                            {/*    className={'pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-stwBlue border-gray-200 transition duration-300'}*/}
                            {/*  />*/}
                            {/*  <label htmlFor={'register-customer-number'} className={'floating-label absolute duration-300 top-3 -z-1 origin-0 text-gray-500'}>Kundennummer (6-stellig)</label>*/}
                            {/*</div>*/}
                            {/*<div className={'relative z-0 w-full'}>*/}
                            {/*  <label className={'inline-flex items-center'}>*/}
                            {/*    <input*/}
                            {/*      type={'checkbox'}*/}
                            {/*      name={'register-checkbox-datenschutz'}*/}
                            {/*      className={*/}
                            {/*        'w-6 h-6 rounded-md border-0 focus:ring-0 text-green-600'*/}
                            {/*      }*/}
                            {/*      required*/}
                            {/*    />*/}
                            {/*    <span className={'ml-2'}>*/}
                            {/*      Ja, ich stimme der{' '}*/}
                            {/*      <Link to={'content/datenschutz'}>Datenschutzerklärung</Link>{' '}*/}
                            {/*      zu.*/}
                            {/*    </span>*/}
                            {/*  </label>*/}
                            {/*</div>*/}
                            <div className={'z-0 w-full flex items-center'}>
                                <div className={'w-6'}>
                                    <input
                                        type={'checkbox'}
                                        className={'rounded-md border-0 focus:ring-0 text-green-600 mr-2'}
                                        id={'register-checkbox-privacyPolicy'}
                                        required
                                        onClick={(e) => {
                                            const checked = e.currentTarget.checked;
                                            setRegisterFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    checkboxes: {
                                                        ...prev.checkboxes,
                                                        privacyPolicy: checked,
                                                    },
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'leading-4'}>
                                    <label
                                        htmlFor={'register-checkbox-privacyPolicy'}
                                        className={'text-justify text-xs'}
                                    >
                                        Ja, ich stimme der{' '}
                                        <Link to={'content/datenschutz'} className={'text-primary'}>
                                            Datenschutzerklärung
                                        </Link>{' '}
                                        zu.
                                    </label>
                                </div>
                            </div>
                            <div className={'z-0 w-full flex items-center'}>
                                <div className={'w-6'}>
                                    <input
                                        type={'checkbox'}
                                        className={'rounded-md border-0 focus:ring-0 text-green-600 mr-2'}
                                        id={'register-checkbox-newsletter'}
                                        onClick={(e) => {
                                            const checked = e.currentTarget.checked;
                                            setRegisterFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    checkboxes: {
                                                        ...prev.checkboxes,
                                                        newsletter: checked,
                                                    },
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'leading-4'}>
                                    <label htmlFor={'register-checkbox-newsletter'} className={'text-justify text-xs'}>
                                        Ja, ich möchte den Newsletter der STW-App abonnieren und Informationen,
                                        Aktionen, Gutscheine und Gewinnspiele über die Produkte der STW sowie all ihre
                                        Tochterunternehmen erhalten. (optional)
                                    </label>
                                </div>
                            </div>
                            <div className={'z-0 w-full flex items-center'}>
                                <div className={'w-6'}>
                                    <input
                                        type={'checkbox'}
                                        className={'rounded-md border-0 focus:ring-0 text-green-600 mr-2'}
                                        id={'register-checkbox-dataProcessing'}
                                        onClick={(e) => {
                                            const checked = e.currentTarget.checked;
                                            setRegisterFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    checkboxes: {
                                                        ...prev.checkboxes,
                                                        dataProcessing: checked,
                                                    },
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                <div className={'leading-4'}>
                                    <label
                                        htmlFor={'register-checkbox-dataProcessing'}
                                        className={'text-justify text-xs'}
                                    >
                                        Ja ich möchte Angebote, Neuigkeiten und Informationen zu Zusatzleistungen
                                        bekommen. Ich bin auch damit einverstanden, dass die Stadtwerke Klagenfurt AG
                                        meine personenbezogenen Daten, nämlich Vor- und Zuname, Titel, Geburtsdatum,
                                        Anschrift, Telefonnummer, E-Mail-Adresse, Verbrauchs-, Vertrags- und
                                        Verrechnungsdaten sowie weitere Informationen, die im Laufe einer allfälligen
                                        Geschäftsbeziehung erfasst werden, zum Zwecke der Zusendung individueller
                                        Angebote, Informationen zu Neuheiten, Produkten und Zusatzleistungen der
                                        Stadtwerke Klagenfurt AG auf telefonischem und elektronischem Wege (insbesondere
                                        E-Mail) verarbeitet. (optional)
                                    </label>
                                </div>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={recaptchaSiteKey}
                                className={'flex justify-center'}
                            />
                            <div className={'flex justify-center'}>
                                <button
                                    type={'submit'}
                                    className={
                                        'flex justify-center items-center transition-all duration-200 px-6 bg-primary text-white font-semibold text-14 uppercase py-3 w-full rounded-6xl focus:bg-primary-dark hover:bg-primary-dark h-12 disabled:opacity-70 mt-8'
                                    }
                                    disabled={registering || !registerFormData.checkboxes.privacyPolicy}
                                >
                                    {registering ? (
                                        <svg
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='animate-spin'
                                            viewBox='0 0 1792 1792'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z' />
                                        </svg>
                                    ) : (
                                        'Registrieren'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
