/* eslint-disable react-hooks/exhaustive-deps */
import './intro.scss';
import { useHistory } from 'react-router-dom';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { useEffect, useState } from 'react';
import { SettingsAPI } from '../../api';
import { IntroItem } from '../../dto';
import Slider from 'react-slick';

const Intro = () => {
    const stwContext: STWContextValues = useSTWContext();
    const history = useHistory();

    const [introItems, setIntroItems] = useState<IntroItem[]>([]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
    };

    useEffect(() => {
        if (stwContext.loggedIn) {
            history.push('/dashboard');
        }
    }, [stwContext.loggedIn]);

    useEffect(() => {
        SettingsAPI.getSetting('intro').then((items: IntroItem[]) => {
            setIntroItems(items);
        });
    }, []);

    return (
        <div className={'relative'}>
            <div className={'intro'}>
                {introItems.length > 0 && (
                    <Slider {...settings} className={'w-full h-slider image-slider -z-1'}>
                        {introItems.map((item, index) => {
                            return (
                                <div className={'relative'} key={index}>
                                    <div
                                        className={'bg-no-repeat bg-cover bg-center'}
                                        style={{
                                            backgroundImage: `url("${item.image}")`,
                                        }}
                                    />
                                    <div
                                        className={'absolute top-0 px-3 z-20 h-screen flex items-center justify-center'}
                                    >
                                        <div style={{ height: 'auto' }}>
                                            <h1
                                                className={'text-32 font-bold text-center'}
                                                style={{
                                                    color: item.title_color ?? '#ffffff',
                                                }}
                                            >
                                                {item.title}
                                            </h1>
                                            <p
                                                className={'text-15 font-medium text-center mt-5'}
                                                style={{
                                                    color: item.text_color ?? '#ffffff',
                                                }}
                                            >
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                )}
            </div>
            <div className={'fixed bottom-0 w-full p-10 pb-16 z-20'}>
                <div className={'flex justify-center mb-5'}>
                    <img src={'/stw_logo_white_mobile.svg'} alt={'Logo'} />
                </div>
                <p className={'text-center text-white uppercase text-12 font-semibold mb-4'}>Weiter mit</p>
                <button
                    className={'bg-primary rounded-full text-white uppercase text-center w-full p-3'}
                    onClick={() => {
                        history.push('/login');
                    }}
                >
                    Anmelden
                </button>
            </div>
        </div>
    );
};

export default Intro;
