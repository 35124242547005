import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { MdOutlineIosShare } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';

export type NotificationNames =
    | 'requestOfferStromFernwaermeAnonymous'
    | 'requestOfferStromFernwaerme'
    | 'requestDefaultOffer'
    | 'stwsAlreadyReceived'
    | 'stwsAlreadyReceivedToday'
    | 'resetPasswordMailSent'
    | 'doRecaptcha'
    | 'doneInvest'
    | 'welcomeStws'
    | 'welcomeMessage'
    | 'bonusStwError'
    | 'registerError'
    | 'registerSuccess'
    | 'generalError'
    | 'passwordResetWrongMail'
    | 'wrongPasswordFormat'
    | 'noRedirectLink'
    | 'afterSharing'
    | 'passwordChanged'
    | 'registerMail'
    | 'howToInstallWelcomeMessage'
    | 'updateAvailable'
    | 'buyButtonLinkRedirectAnonymous'
    | 'raffleJoined'
    | 'raffleAlreadyJoined'
    | 'howToInstallOnAndroid'
    | 'howToInstallOnIOS'
    | 'qrCodeBrightness';

type OS = 'android' | 'ios' | 'unknown';

export const Notifications: {
    [key in NotificationNames]: {
        type: 'info' | 'error' | 'success';
        message: ToastContent;
    };
} = {
    generalError: {
        type: 'error',
        message: 'Irgendwas ist hier leider schiefgelaufen - bitte probiere es nochmals oder lade die Seite neu.',
    },
    requestOfferStromFernwaermeAnonymous: {
        type: 'info',
        message: 'Bitte registriere dich oder logge dich ein, damit wir dir ein Angebot senden können.',
    },
    buyButtonLinkRedirectAnonymous: {
        type: 'info',
        message: 'Bitte registriere dich oder logge dich ein, um dieses Produkt kaufen zu können.',
    },
    requestOfferStromFernwaerme: {
        type: 'success',
        message:
            'Danke Superheld! Deine Bonus-STWs wurden dir bereits gutgeschrieben! Denk dran: Mit großer Macht kommt auch große Verantwortung also lass uns gemeinsam Klagenfurt schöner machen!',
    },
    requestDefaultOffer: {
        type: 'info',
        message: 'Deine Bonus-STWs wurden dir gutgeschrieben!',
    },
    stwsAlreadyReceivedToday: {
        type: 'info',
        message: 'Sorry, für heute hast du deine STWs schon bekommen.',
    },
    stwsAlreadyReceived: {
        type: 'info',
        message: 'Sorry, du hast deine STWs schon bekommen.',
    },
    resetPasswordMailSent: {
        type: 'success',
        message:
            'Auch Superhelden vergessen mal das Passwort. Ein Link zum Zurücksetzen deines Passworts wurde dir per E-Mail gesendet.',
    },
    doRecaptcha: {
        type: 'info',
        message: 'Zeige deine Superkräfte, damit wir wissen, dass du kein Roboter bist!',
    },
    doneInvest: {
        type: 'success',
        message:
            'Gemeinsam machen wir Klagenfurt schöner! Wir wissen als Superheld bist du bescheiden, aber erzähl allen davon, damit dieses Projekt auch wirklich gewinnt!',
    },
    welcomeStws: {
        type: 'success',
        message: 'Herzlichen Glückwunsch! Der erste Schritt ist getan. Deine Start-STWs wurden dir gutgeschrieben!',
    },
    welcomeMessage: {
        type: 'info',
        message:
            'Willkommen, schön dass du da bist! In dieser App findest du regelmäßig ausgewählte Mach‘s Schöner Projekte, die wir mit deiner Unterstützung umsetzen möchten! Als STW-Kunde bekommst du monatlich STWs für deinen Strom- oder Fernwärmevertrag. Weil du dich eingeloggt hast, haben wir dir den Startbonus bereits gutgeschrieben! Sei ein Held und investiere deine STWs in dein Lieblingsprojekt. Die Zukunft liegt in deiner Hand! Du hast noch Fragen? Antworten dazu gibt es in unseren FAQs! Möge unsere Energie mit dir sein!',
    },
    bonusStwError: {
        type: 'error',
        message:
            'Beim Verbuchen deiner Start-STWs für das erste Login ist leider etwas schiefgelaufen - bitte kontaktiere marketing@stw.at',
    },
    registerError: {
        type: 'error',
        message:
            'Bei der Registrierung ist leider etwas schiefgelaufen, bitte versuche es nochmal oder wende dich an marketing@stw.at',
    },
    registerMail: {
        type: 'info',
        message:
            'Super, die Registrierung war erfolgreich. Wir haben dir eine Mail zugesendet - bitte bestätige mit dem Link deinen Account.',
    },
    registerSuccess: {
        type: 'success',
        message:
            'Gratuliere, deine Registrierung war erfolgreich! Du kannst dich nun mit deinen Daten einloggen und Klagenfurt schöner machen!',
    },
    passwordResetWrongMail: {
        type: 'error',
        message:
            'Leider konnten wir dich mit der angegeben E-Mail Adresse nicht finden. Bitte versuche es nochmals mit einer anderen E-Mail Adresse.',
    },
    wrongPasswordFormat: {
        type: 'error',
        message: 'Bitte wähle ein anderes Passwort.',
    },
    passwordChanged: {
        type: 'success',
        message: 'Dein Passwort wurde erfolgreich zurückgesetzt.',
    },
    noRedirectLink: {
        type: 'info',
        message:
            'Gratuliere, du hast einen Fehler bei uns entdeckt! Bitte melde dich bei marketing@stw.at und wir schicken dir gleich ein kleines Geschenk!',
    },
    afterSharing: {
        type: 'success',
        message: 'Danke fürs Teilen! Wir würden dich jetzt umarmen, wenn wir könnten!',
    },
    howToInstallWelcomeMessage: {
        type: 'info',
        message: 'Willst du die App auf deinem Handy installieren? Wie das funktioniert findest du in unseren FAQs!',
    },
    updateAvailable: {
        type: 'info',
        message: 'Update verfügbar - jetzt auf die Benachrichtigung klicken und auf die neueste Version updaten!',
    },
    raffleJoined: {
        type: 'success',
        message: 'Danke Superheld:in! Du machst somit beim Gewinnspiel mit!',
    },
    raffleAlreadyJoined: {
        type: 'info',
        message: 'Du nimmst bereits am Gewinnspiel teil.',
    },
    howToInstallOnAndroid: {
        type: 'info',
        message:
            <>
                <b>App installieren - so geht's!</b>
                <ol>
                    <li>1. Oben rechts auf die 3 Punkte klicken <BsThreeDotsVertical style={{ display: 'inline-block' }} /></li>
                    <li>2. Klicke auf: “Zum Startbildschirm hinzufügen”</li>
                    <li>3. Schon ist die App auf deinem Bildschirm gespeichert!</li>
                </ol>
            </>,
    },
    howToInstallOnIOS: {
        type: 'info',
        message:
            <>
                <b>App installieren - so geht's!</b>
                <ol>
                    <li>1. Klick auf den Button <MdOutlineIosShare style={{ display: 'inline-block' }} /></li>
                    <li>2. Zum "Home-Bildschirm"</li>
                    <li>3. Schon ist die App auf deinem Bildschirm gespeichert!</li>
                </ol>
            </>,
    },
    qrCodeBrightness: {
        type: 'info',
        message: 'Superhelden Tipp: Mach deinen Bildschirm heller, damit der QR Code gut gelesen werden kann!',
    },
};

export const showNotification = (name: NotificationNames, options?: ToastOptions) => {
    const notification = Notifications[name];
    toast(notification.message, { type: notification.type, ...options });
};

const getMobileOperatingSystem = (): OS => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
    }

    return 'unknown';
};

export const showInstallationNotification = (lsItemName: string) => {
    const lsItem = localStorage.getItem(lsItemName);

    if (lsItem !== '' && lsItem !== null) {
        return;
    }

    const os: OS = getMobileOperatingSystem();

    if (os === 'unknown') {
        return;
    }

    if (os === 'ios') {
        showNotification('howToInstallOnIOS', {
            autoClose: false,
        });
    } else {
        showNotification('howToInstallOnAndroid', {
            autoClose: false,
        });
    }

    localStorage.setItem(lsItemName, (new Date()).toTimeString());
};
