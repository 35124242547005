/* eslint-disable react-hooks/exhaustive-deps */
import './project-detail.scss';
import { useEffect } from 'react';
import ImageSlider from '../image-slider/ImageSlider';
import Headline from '../headline/Headline';
import ProjectDetailStwsSupport from './project-detail-stws-support/ProjectDetailStwsSupport';
import ProjectDetailOverview from './project-detail-overview/ProjectDetailOverview';
import ProjectDetailDescription from './project-detail-description/ProjectDetailDescription';
import Footer from '../footer/Footer';
import { useParams } from 'react-router-dom';
import { ProjectAPI } from '../../api';
import ProjectDetailSupportBar from './project-detail-support-bar/ProjectDetailSupportBar';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { ProjectsValues, useProjectsContext } from '../../contexts/ProjectsContext';

const ProjectDetail = () => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const { projectID } = useParams<{ projectID: string }>();

    useEffect(() => {
        projectsContext.setState((prev) => {
            return {
                ...prev,
                projectDetail: {
                    ...prev.projectDetail,
                    loadingData: true,
                },
            };
        });

        ProjectAPI.getProject(parseInt(projectID))
            .then((projectData) => {
                projectsContext.setState((prev) => {
                    return {
                        ...prev,
                        projectDetail: {
                            ...prev.projectDetail,
                            data: projectData,
                            loadingData: false,
                        },
                    };
                });
            })
            .catch((e) => console.error(e));
    }, [projectID, stwContext.user?.stwAmount]);

    return (
        <div className={'project-detail'}>
            {!projectsContext.projectDetail.loadingData && projectsContext.projectDetail.data ? (
                <div className={'container mx-auto'}>
                    {projectsContext.projectDetail.data.images &&
                        projectsContext.projectDetail.data.images.length > 0 && (
                            <ImageSlider images={projectsContext.projectDetail.data.images} />
                        )}
                    <div className={'bg-stwGreen-light rounded-tr-80 rounded-bl-80 -mt-40 p-7 z-20 relative'}>
                        <Headline
                            headline={projectsContext.projectDetail.data.title}
                            subheadline={projectsContext.projectDetail.data.subtitle}
                        />
                        <ProjectDetailStwsSupport />
                        <ProjectDetailOverview />
                        <ProjectDetailDescription />
                    </div>
                    <Footer />
                    <ProjectDetailSupportBar />
                </div>
            ) : (
                <div className={'container mx-auto'}>
                    <div className={'bg-gray-400 h-slider rounded-lg animate-pulse'} />
                    <div className={'bg-stwGreen-light rounded-tr-80 rounded-bl-80 -mt-40 p-7 z-20 relative'}>
                        <div className={'bg-gray-400 h-8 rounded-lg animate-pulse mb-2 w-4/5'} />
                        <div className={'bg-gray-400 h-6 rounded-lg animate-pulse w-3/5'} />
                        <ProjectDetailStwsSupport />
                        <ProjectDetailOverview />
                        <ProjectDetailDescription />
                    </div>
                    <Footer />
                    <ProjectDetailSupportBar />
                </div>
            )}
        </div>
    );
};

export default ProjectDetail;
