import './invest-button.scss';
import BasicNumber from '../utils/basic-number/BasicNumber';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import { BlockchainApi } from '../../api';
import { ProjectsValues, useProjectsContext } from '../../contexts/ProjectsContext';
import { showNotification } from '../../notifications';
import party from 'party-js';

const InvestButton = (props: { amount: number; investID?: number; text?: string }) => {
    const stwContext: STWContextValues = useSTWContext();
    const projectsContext: ProjectsValues = useProjectsContext();

    const enoughStws: boolean = stwContext.user?.stwAmount !== undefined && stwContext.user?.stwAmount >= props.amount;
    const disabled: boolean = !enoughStws || props.amount <= 0;

    const handleInvest = (e: any) => {
        if (stwContext.user && props.investID) {
            BlockchainApi.add({
                source_id: stwContext.user?.id,
                source_type: 'user',
                target_id: props.investID,
                target_type: 'app_project',
                amount: props.amount,
                transaction_type: 'invest',
            })
                .then((response) => {
                    if (response.success) {
                        stwContext.setState((prev) => {
                            if (prev.user !== null) {
                                return {
                                    ...prev,
                                    user: {
                                        ...prev.user,
                                        stwAmount: prev.user.stwAmount - props.amount,
                                    },
                                };
                            } else {
                                return prev;
                            }
                        });
                        projectsContext.setState((prev) => {
                            return {
                                ...prev,
                                projectDetail: {
                                    ...prev.projectDetail,
                                    supportBar: !prev.projectDetail.supportBar,
                                },
                            };
                        });
                    }

                    party.confetti(e.target, {
                        count: party.variation.range(30, 50),
                    });
                    showNotification('doneInvest');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <button type={'button'} className={'invest-button'} disabled={disabled} onClick={handleInvest}>
            {props.text && <span className={'formatted-number'}>{props.text}</span>}
            {!props.text && <BasicNumber amount={props.amount} suffix={' STWs'} />}
        </button>
    );
};

export default InvestButton;
