import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import TagManager from 'react-gtm-module';
import { CookieHelper } from './cookie-helper';
import { toast } from 'react-toastify';
import { Notifications } from './notifications';

const cookieConsentCookie: string | null = CookieHelper.readCookie('cookie-consent-choice');

if (cookieConsentCookie === 'all') {
    TagManager.initialize({
        gtmId: 'GTM-THFS938',
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

let checkForUpdateInterval: any = null;

window.addEventListener('load', () => {
    checkForUpdate();
});

navigator.serviceWorker.ready.then(() => {
    // checkForUpdate();

    checkForUpdateInterval = setInterval(() => {
        checkForUpdate();
    }, 300000);
});

const checkForUpdate = () => {
    fetch('/version.json')
        .then(async (data) => {
            const json = await data.json();
            const version = json.version;
            const currentVersion = localStorage.getItem('app_version');

            if (currentVersion === null || currentVersion === '') {
                localStorage.setItem('app_version', version);
                if (checkForUpdateInterval) {
                    clearInterval(checkForUpdateInterval);
                }
                return;
            }

            if (currentVersion !== version) {
                toast(Notifications.updateAvailable.message, {
                    type: Notifications.updateAvailable.type,
                    autoClose: false,
                    closeButton: false,
                    onClick: () => {
                        const unregister = serviceWorkerRegistration.unregister();

                        if (unregister) {
                            unregister.then(() => {
                                caches.keys().then((cacheNames) => {
                                    console.log('clear caches');
                                    return Promise.all(
                                        cacheNames.map((cacheName) => {
                                            return caches.delete(cacheName);
                                        })
                                    );
                                });

                                localStorage.setItem('app_version', version);
                                window.location.reload();
                            });
                        }
                    },
                });
                if (checkForUpdateInterval) {
                    clearInterval(checkForUpdateInterval);
                }
            }
        })
        .catch(() => {
            console.error('failed fetching app version');
        });
};

serviceWorkerRegistration.register();
