import './dashboard.scss';
import Footer from '../footer/Footer';
import DashboardUserOverview from './dashboard-user-overview/DashboardUserOverview';
import DashboardProductList from './dashboard-product-list/DashboardProductList';
import DashboardUserProjectList from './dashboard-user-project-list/DashboardUserProjectList';
import { STWContextValues, useSTWContext } from '../../contexts/STWContext';
import DashboardNewsList from './dashboard-news-list/DashboardNewsList';
import { useEffect } from 'react';
import { showInstallationNotification } from '../../notifications';

const Dashboard = () => {
    const stwContext: STWContextValues = useSTWContext();

    useEffect(() => {
        showInstallationNotification('pwa-installation-notification-dashboard');
    }, []);

    return (
        <div className={'dashboard container mx-auto'}>
            {stwContext.loggedIn && (
                <div>
                    <DashboardUserOverview />
                    <DashboardUserProjectList />
                </div>
            )}
            <DashboardProductList />
            <DashboardNewsList />
            <Footer />
        </div>
    );
};

export default Dashboard;
