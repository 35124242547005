import ProjectDetail from './components/project-detail/ProjectDetail';
import STWContextProvider from './contexts/STWContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Nav from './components/nav/Nav';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import ProductDetailContextProvider from './contexts/ProductDetailContext';
import ProductDetail from './components/product-detail/ProductDetail';
import ProjectsContextProvider from './contexts/ProjectsContext';
import Intro from './components/intro/Intro';
import ProductsList from './components/products/products-list/ProductsList';
import Content from './components/content/Content';
import NewsDetail from './components/news-detail/NewsDetail';
import { ToastContainer } from 'react-toastify';
import PasswordForgotten from './components/password-forgotten/PasswordForgotten';
import ResetPassword from './components/reset-password/ResetPassword';
import DigitalTickets from './components/digital-tickets/DigitalTickets';
import { CookieHelper } from './cookie-helper';
import Projects from './components/projects/Projects';

export function App() {
    return (
        <STWContextProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {CookieHelper.readCookie('cookie-consent-dialog') === null && (
                <>
                    <div className={'fixed top-0 w-screen h-screen p-0 m-0 bg-gray-500/50'} style={{ zIndex: 99998 }} />
                    <div
                        className={
                            'fixed top-1/2 -translate-y-2/4 text-stwGray-dark bg-transparent shadow-2xl px-4 py-2 overflow-y-scroll'
                        }
                        style={{ zIndex: 99999, maxHeight: '95vh' }}
                    >
                        <div className={'px-5 bg-white rounded-lg'}>
                            <div className={'flex justify-center items-center pt-2'}>
                                <img src={'/stw_logo_rgb_mobile.svg'} alt={'Logo Datenschutz'} />
                            </div>
                            <h1 className={'text-center text-20 break-words pb-3 pt-1'}>Datenschutzeinstellungen</h1>
                            <div className={'space-y-3'}>
                                <p className={'text-14'}>
                                    Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während
                                    andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.
                                </p>
                                <p className={'text-14'}>
                                    Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben
                                    möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.
                                </p>
                                <p className={'text-14'}>
                                    Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen
                                    sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu
                                    verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z.
                                    B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung.
                                    Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer{' '}
                                    <a
                                        className={'text-primary hover:text-primary-light focus:text-primary-light'}
                                        href={'https://stw.at/datenschutz'}
                                    >
                                        Datenschutzerklärung
                                    </a>
                                    .
                                </p>
                            </div>
                            <div className={'px-6 pb-2 pt-4 space-y-2'}>
                                <button
                                    className={'bg-stwGray-mid opacity-75 text-white rounded-md text-14 w-full py-1'}
                                    onClick={() => {
                                        CookieHelper.createCookie('cookie-consent-dialog', true, 365);
                                        CookieHelper.createCookie('cookie-consent-choice', 'must-use', 365);
                                        window.location.reload();
                                    }}
                                >
                                    Notwendige akzeptieren
                                </button>
                                <button
                                    className={'bg-primary text-white rounded-md text-14 w-full py-1'}
                                    onClick={() => {
                                        CookieHelper.createCookie('cookie-consent-dialog', true, 365);
                                        CookieHelper.createCookie('cookie-consent-choice', 'all', 365);
                                        window.location.reload();
                                    }}
                                >
                                    Alle akzeptieren
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <Router>
                <Switch>
                    <Route exact path={'/dashboard'}>
                        <ProjectsContextProvider>
                            <Nav darkIcons={true} />
                            <Dashboard />
                        </ProjectsContextProvider>
                    </Route>
                    <Route exact path={'/login'}>
                        <Nav darkIcons={true} />
                        <Login />
                    </Route>
                    <Route exact path={'/password-forgotten'}>
                        <Nav darkIcons={false} />
                        <PasswordForgotten />
                    </Route>
                    <Route path={'/set-password/:key'}>
                        <Nav darkIcons={false} />
                        <ResetPassword />
                    </Route>
                    <Route path={'/products'}>
                        <Nav darkIcons={true} />
                        <ProductsList />
                    </Route>
                    <Route path={'/projects'}>
                        <ProjectsContextProvider>
                            <Nav darkIcons={true} />
                            <Projects />
                        </ProjectsContextProvider>
                    </Route>
                    <Route path={'/project/:projectID'}>
                        <ProjectsContextProvider>
                            <Nav />
                            <ProjectDetail />
                        </ProjectsContextProvider>
                    </Route>
                    <Route path={'/product/:productID'}>
                        <ProductDetailContextProvider>
                            <Nav />
                            <ProductDetail />
                        </ProductDetailContextProvider>
                    </Route>
                    <Route path={'/news/:newsID'}>
                        <Nav />
                        <NewsDetail />
                    </Route>
                    <Route path={'/content/:name'}>
                        <Nav />
                        <Content />
                    </Route>
                    <Route exact path={'/digital-tickets'}>
                        <Nav darkIcons={true} />
                        <DigitalTickets />
                    </Route>
                    <Route path={'/'}>
                        <Nav darkIcons={false} />
                        <Intro />
                    </Route>
                </Switch>
            </Router>
        </STWContextProvider>
    );
}

export default App;
