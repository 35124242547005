import './stws.scss';
import BasicNumber from '../utils/basic-number/BasicNumber';

const Stws = (props: { amount: number | null | undefined }) => {
    return (
        <span className={'stws'}>
            <BasicNumber
                amount={(props.amount !== null && props.amount !== undefined ? props.amount : 0) < 0 ? 0 : props.amount}
                customClass={'stws-number'}
            />
            <span className={'stws-text'}> STW</span>
            <span className={'stws-text lowercase'}>s</span>
        </span>
    );
};

export default Stws;
